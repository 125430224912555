const title = 'Professional Home Staging & Home Goods | Guest House';
const description = 'Guest House makes it easy to have a beautiful space. Our home staging delivers premium furnishings and real interior designers in as quick as a week.';
const imageUrl = 'https://firebasestorage.googleapis.com/v0/b/guesthouse-cms-dev.appspot.com/o/public%2Fguesthouse-social-default.jpg?alt=media';
const url = 'https://guesthouseshop.com/';

const defaultSeo = {
  openGraph: {
    title,
    site_name: title,
    type: 'website',
    url,
    description,
    images: [
      {
        url: imageUrl,
      },
    ],
  },
  twitter: {
    url,
    cardType: 'summary_large_image',
  },
  additionalMetaTags: [
    {
      name: 'description',
      content: description,
    }, {
      name: 'title',
      content: title,
    }
  ],
};

export const makeSeoData = ({
  pageTitle = '',
  metaTitle = '',
  metaDescription = '',
  image = '',
}: guesthouse.MetaData = {}) => {
  return {
    ...defaultSeo,
    title: pageTitle,
    openGraph: {
      ...defaultSeo.openGraph,
      title: metaTitle,
      description: metaDescription,
      images: [
        {
          url: image
        }
      ]
    },
    additionalMetaTags: [
      {
        name: 'description',
        content: metaDescription,
      }, {
        name: 'title',
        content: pageTitle,
      }
    ]
  };
};

export { title };
export default defaultSeo;
