import { GENERAL_PHONE_NUMBER } from '@data/src/phoneNumbers';
import React from 'react';

export const defaultFooterContext: FooterContext = {
  phoneNumberText: GENERAL_PHONE_NUMBER,
  phoneNumberHref: `tel:1-${GENERAL_PHONE_NUMBER}`,
  setContext: () => {}
};

export default React.createContext<FooterContext>(defaultFooterContext);
