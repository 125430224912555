import { functions } from '@app/firebase';
import FancyInput from '@components/fancy-input/FancyInput';
import Sentry from '@integrations/Sentry';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { ERROR } from '@ui/theme/theme';
import { useStyles } from '@ui/utils/makeStyles';
import { httpsCallable } from 'firebase/functions';
import serialize from 'form-serialize';
import React, { useState } from 'react';

type EmailSignupProps = {
  campaignList: 'shoppers' | 'makers' | 'realtors' | 'investors' | 'dibs';
  onSuccess?: (email?: string) => void;
  customFields?: Record<string, string>;
}

const createHubspotContactFromEmailSignup = httpsCallable(functions, 'hubspot-createHubspotContactFromEmailSignup');

const EmailSignup: React.FC<EmailSignupProps> = (props) => {
  const { campaignList, onSuccess } = props;
  const { theme } = useStyles();
  const [error, setError] = useState<Error | undefined>();
  const [modalOpen, setModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <>
      <FancyInput
        type="email"
        name="EMAIL"
        placeholder="name@email.com"
        data-test="fancy-input-email"
        defaultValue=""
        isSubmitting={isSubmitting}
        onSubmit={async (e) => {
          e.preventDefault();
          setIsSubmitting(true);
          setError(undefined);
          const query = serialize(e.target, { hash: true });

          try {
            await createHubspotContactFromEmailSignup({
              email: query.EMAIL,
              email_signup: true,
              email_campaign_list: campaignList
            }).then(() => {
              if (typeof onSuccess === 'function') {
                onSuccess();
              } else {
                setModalOpen(true);
              }
  
              try {
                window.analytics?.track('email_signup', {
                  name: 'shopper'
                });
              } catch (e) {
                Sentry.captureException(e);
              }
            });
          } catch (e) {
            Sentry.captureException(e);
            setError(e);
            setModalOpen(false);
          }

          setIsSubmitting(false);
        }}
      >
        <div
          style={{ position: 'absolute', left: -9999, }}
          aria-hidden="true"
        >
          <input
            type="text"
            name={`${campaignList}-list-sendgrid`}
            tabIndex={-1}
            defaultValue=""
          />
        </div>
      </FancyInput>

      {error && (
        <FormHelperText style={{ marginTop: theme.spacing(4), color: ERROR }}>
          <span dangerouslySetInnerHTML={{ __html: error.message }} />
        </FormHelperText>
      )}

      <Dialog
        fullWidth
        open={modalOpen}
        aria-labelledby="email-signup-confirmation-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        onClose={() => setModalOpen(false)}
      >
        <DialogContent>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            style={{ padding: theme.spacing(4) }}
          >
            <Typography
              variant="h2"
              style={{ margin: theme.spacing(4, 0, 2) }}
            >
              You&apos;re all set!
            </Typography>

            <Typography style={{ margin: theme.spacing(2, 0, 4) }}>
              Thanks for joining our newsletter.
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default EmailSignup;
