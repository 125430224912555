import * as Yup from 'yup';

const EmailSchema = Yup.object().shape({
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('A valid email address is required'),
});

export const initialValues: EmailForm = {
  email: '',
};

export default EmailSchema;
