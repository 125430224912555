import Button, { ButtonProps } from '@mui/material/Button';
import Link, { LinkProps } from 'next/link';
import React from 'react';

export type ButtonLinkProps = Omit<ButtonProps, 'href' | 'classes'> &
  Pick<LinkProps, 'href' | 'prefetch'>

const ButtonLink = React.forwardRef<ButtonLinkProps, any>(
  ({ href, prefetch, ...props }, ref) => (
    <Link
      passHref
      href={href}
      prefetch={prefetch}
    >
      <Button
        ref={ref}
        {...props}
      />
    </Link>
  )
);

ButtonLink.displayName = 'ButtonLink';

export default ButtonLink;
