import useDocument from '@hooks/useDocument';
import SvgGuesthouseLogoFull from '@images/guesthouse-logo-full';
import Typography from '@mui/material/Typography';
import { useStyles } from '@ui/utils/makeStyles';
import React, { useMemo } from 'react';

export const Maintenance = () => {
  const { theme } = useStyles();

  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <SvgGuesthouseLogoFull style={{ width: 200, marginBottom: theme.spacing(4) }} />

        <Typography style={{ marginBottom: theme.spacing(3) }}>
          We&apos;re currently down for scheduled maintenance. Please check back soon.
        </Typography>
      </div>
    </div>
  );
};

export default function useMaintenanceMode() {
  const { doc: appSettings, loading: appSettingsLoading } = useDocument('settings/app');
  const maintenanceMode = useMemo(() => {
    
    if (appSettings && !appSettingsLoading && appSettings.data()?.maintenance) {
      return true;
    }
    return false;
  }, [appSettings, appSettingsLoading]);

  return maintenanceMode;
}
