const slugOrID = (obj) => {
  if (!obj) {
    return;
  }
  
  if (obj.slug) {
    return obj.slug;
  }

  return obj.docID;
};

export default slugOrID;
