import UserContext from '@context/UserContext';
import { useRouter } from 'next/router';
import { useContext, useEffect } from 'react';

export default function useHubSpotConversationsWidget() {
  const router = useRouter();
  const userContext = useContext<UserContext>(UserContext);

  const handleRouteChange = () => {
    if (userContext?.tokens?.hsToken) {
      window.hsConversationSettings = {
        identificationEmail: userContext.user.email,
        identificationToken: userContext.tokens.hsToken,
      };

      window.HubSpotConversations.widget.load();
    } else {
      try {
        window.HubSpotConversations.widget.load();
      } catch (e) {
        // do nothing, not all pages have this widget enabled in HubSpot configuration.
      }
    }
  };

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router]);
}
