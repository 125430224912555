import NotificationContext from '@context/NotificationContext';
import Alert from '@mui/material/Alert';
import Snackbar from '@mui/material/Snackbar';
import React, { useContext } from 'react';

const Notification = () => {
  const notificationContext = useContext<NotificationContext>(NotificationContext);
  const handleClose = () => notificationContext.setContext({ open: false, severity: 'info' });

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      open={notificationContext.open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert
        data-test="notification"
        severity={notificationContext.severity || 'info'}
        elevation={6}
        variant="filled"
      >
        {notificationContext.message}
      </Alert>
    </Snackbar>
  );
};

export default Notification;
