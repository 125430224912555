import { auth, database } from '@app/firebase';
import Sentry from '@integrations/Sentry';
import * as EmailValidator from 'email-validator';
import { fetchSignInMethodsForEmail } from 'firebase/auth';
import { collection, getDocs, query, where } from 'firebase/firestore';

export const instagramHandle = async (value) => {
  if (!value) {
    return true;
  }
  try {
    // Source: https://blog.jstassen.com/2016/03/code-regex-for-instagram-username-and-hashtags/
    const instagramHandleRegEx = /[A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?/;
    const instagramHandleMatches = value.match(instagramHandleRegEx);

    if (instagramHandleMatches && instagramHandleMatches[0] === instagramHandleMatches.input) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

// Not to be used in schema validation, only to validate a single field
// See ./cms/forms/PromoCode.tsx for implementation specifics
export const customCode = async (value, originalValue = null): Promise<string | undefined> => {
  if (!value || originalValue === value) {
    return;
  }
  
  try {
    const { docs } = await getDocs(query(collection(database, 'coupons')
      ,where('code', '==', value)));

    if (docs.length) {
      return 'Duplicate or invalid code, please try again';
    }
  } catch (e) {
    Sentry.captureException(e);
    return 'Code check failed, please try that code again';
  }
};

export const validateEmailNotExists = async (email: string) => {
  if (EmailValidator.validate(email)) {
    const ret = await fetchSignInMethodsForEmail(auth, email);

    if (ret.length) {
      return 'The email address is already in use by another account.';
    }
  }
};

export const validateEmailExists = async (email: string) => {
  if (EmailValidator.validate(email)) {
    const ret = await fetchSignInMethodsForEmail(auth, email);

    if (!ret.length) {
      return 'Sorry, we could not find an account associated with this email address.';
    }
  }
};
