import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import IconAdd from '@ui/icons/imaterial/base/Add.svg';
import IconRemove from '@ui/icons/imaterial/base/Stop.svg';
import React, { useMemo } from 'react';

import theme from '../../theme/theme';
import useStyles from './QuantitySelect.style';

type QuantitySelectProps = {
  count: number;
  min?: number;
  minTooltip?: React.ReactNode;
  max?: number;
  maxTooltip?: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  buttonSize?: 'xs' | 'small' | 'medium' | 'large';
  onUp?: (count: number) => void;
  onDown?: (count: number) => void;
}

const QuantitySelect: React.FC<QuantitySelectProps> = (props) => {
  const { count, min, max, onUp, onDown, className, style, disabled, minTooltip, maxTooltip, buttonSize } = props;
  const { classes, cx } = useStyles();

  const minDisabled = typeof min === 'number' && count <= min;
  const maxDisabled = typeof max === 'number' && count >= max || disabled;

  const displayUpIncrementer = useMemo(() => typeof onUp === 'function', [onUp]);
  const displayDownIncrementers = useMemo(() => typeof onDown === 'function', [onDown]);

  return (
    <Box
      className={cx(classes.quantitySelect, className)}
      style={style}
      display="flex"
      flexDirection="row"
      justifyContent="center"
      alignItems="center"
    >
      {displayDownIncrementers && (
        <Tooltip title={minTooltip || ''}>
          <div>
            <IconButton
              className={classes.iconButton}
              size={buttonSize}
              disabled={minDisabled}
              aria-label={'Remove'}
              data-test="icon-button-decrement"
              onClick={() => onDown && onDown(count - 1)}
            >
              <IconRemove
                style={{ fill: minDisabled ? 'grey' : theme.palette.common.black }}
              />
            </IconButton>
          </div>
        </Tooltip>
      )}

      {!displayUpIncrementer && !displayDownIncrementers && (
        <Typography
          className={classes.qtyText}
        >
          {`Qty. ${count}`}
        </Typography>
      )}

      {displayUpIncrementer && displayDownIncrementers && (
        <div className={classes.count}>
          {count}
        </div>
      )}

      {displayUpIncrementer && (
        <Tooltip title={maxTooltip || ''}>
          <div>
            <IconButton
              className={classes.iconButton}
              size={buttonSize}
              aria-label={'Add'}
              disabled={maxDisabled}
              data-test="icon-button-increment"
              onClick={() => onUp && onUp(count + 1)}
            >
              <IconAdd
                style={{ fill: maxDisabled ? 'grey' : theme.palette.common.black }}
              />
            </IconButton>
          </div>
        </Tooltip>
      )}
    </Box>
  );
};

QuantitySelect.defaultProps = {
  count: 0,
  min: 0,
  buttonSize: 'xs',
};

export default QuantitySelect;
