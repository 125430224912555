import { useEffect,useState } from 'react';

export default function useVisualViewportHeight() : number {
  const [height, setHeight] = useState<number>();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    const resize = () => {
      // @ts-ignore
      if (window.visualViewport) {
        // @ts-ignore
        setHeight(window.visualViewport.height);
      } else {
        setHeight(window.innerHeight);
      }
    };

    resize();

    // @ts-ignore
    if (window.visualViewport) {
      // @ts-ignore
      window.visualViewport.addEventListener('resize', resize);
    } else {
      document.addEventListener('resize', resize);
    }

    return () => {
      // @ts-ignore
      if (window.visualViewport) {
        // @ts-ignore
        window.visualViewport.removeEventListener('resize', resize);
      } else {
        document.removeEventListener('resize', resize);
      }
    };
  }, []);

  return height;
}
