import PhoneNumber from 'awesome-phonenumber';

const formatPhone =  (phone: string) => {
  const pn = new PhoneNumber(phone, 'US');
  const newPhoneNumber = pn.getNumber('international').replace('-', ' ').replace('-', ' ');

  if (!newPhoneNumber.match(/[+]1 [0-9]{3} [0-9]{3} [0-9]{4}/) || !pn.isValid()) {
    throw Error(`The provided phone number is invalid: ${phone}`);
  }

  return newPhoneNumber;
};

export default formatPhone;
