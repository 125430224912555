const ROUTES = {
  '/': {
    title: 'Home',
    roles: ['*'],
  },
  '/my/account': {
    title: 'Profile',
    roles: ['@'],
  },
  '/my/orders': {
    title: 'Orders',
    roles: ['@'],
  },
  '/my/orders/[id]': {
    title: 'Order Detail',
    roles: ['*'],
  },
  '/my/cart': {
    title: 'Cart',
    roles: ['*'],
  },
  '/my/favorites': {
    title: 'Favorites',
    roles: ['@'],
  },
  '/my/rooms/[room]': {
    title: 'Room',
    roles: ['@'],
  },
  '/products': {
    title: 'Products',
    roles: ['*'],
  },
  '/products/[id]': {
    title: 'Product Detail',
    roles: ['*'],
  },
  login: {
    path: '/',
  },
  account: {
    path: '/my/account',
  },
  orders: {
    path: '/my/orders',
  },
  cart: {
    path: '/my/cart',
  },
  favorites: {
    path: '/my/favorites',
  }
};

export default ROUTES;
