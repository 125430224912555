import PrimaryImage from '@components/primary-image/PrimaryImage';
import Typography from '@mui/material/Typography';
import ProductOffer from '@ui/components/product-offer/ProductOffer';
import { cityState } from '@ui/utils/shortAddress';
import formatCurrency from 'format-currency';
import Link from 'next/link';
import React from 'react';

import useStyles from './CartProduct.style';

type CartProductProps = {
  item: guesthouse.Product | guesthouse.Room;
  href: string;
  textPrimary: string;
  textSecondary: string;
  quantity?: number;
  offer?: guesthouse.ProductOffer;
  price?: number | string;
  renderAction?: (prop?: any) => React.ReactElement;
}

const CartProduct: React.FC<CartProductProps> = (props) => {
  const { item, offer, quantity, href, textPrimary, textSecondary, price, renderAction } = props;

  const { classes, theme } = useStyles();

  if (!item) {
    return null;
  }

  return (
    <div className={classes.productContainer}>
      <div className={classes.productImageContainer}>
        <Link
          href={href}
          style={{ display: 'block' }}
        >

          <PrimaryImage item={item} />

        </Link>
      </div>

      <div style={{ flex: 1 }}>
        <div
          className={classes.productDetailsContainer}
          style={{ alignItems: 'flex-start' }}
        >
          <Link
            legacyBehavior
            href={href}
          >
            <Typography
              className={classes.productTextPrimary}
              component="a"
            >
              {textPrimary}
            </Typography>
          </Link>

          {offer && (
            <ProductOffer
              offer={offer}
              style={{ marginLeft: theme.spacing(1), fontSize: 13, lineHeight: 1.25 }}
            />
          )}
          {price && (
            <Typography>
              {formatCurrency(price, { format: '%s%v', symbol: '$' })}
            </Typography>
          )}
        </div>

        {textSecondary &&
          <Typography className={classes.productTextSecondary} >
            {textSecondary}
          </Typography>
        }

        {offer?.pickupOnly && (
          <Typography
            className={classes.productTextSecondary}
            style={{ fontSize: 10 }}
          >
            {'  '}
            {cityState(offer.pickupLocation?.address)}
            {' '}
            pickup only
          </Typography>
        )}

        <div className={classes.productDetailsContainer}>
          {renderAction && renderAction()}

          {!renderAction && quantity && (
            <Typography
              className={classes.productTextSecondary}
              style={{ marginTop: theme.spacing(1) }}
            >
              Qty:
              {' '}
              {quantity}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
};

export default CartProduct;
