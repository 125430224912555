
import Button, { ButtonProps } from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import React from 'react';

import useStyles from './SubmitButton.style';

interface Props extends ButtonProps {
  isSubmitting?: boolean;
  disabled?: boolean;
  tooltip?: React.ReactNode;
  children?: React.ReactNode;
}

const SubmitButton: React.FC<Props> = (props) => {
  const { children, isSubmitting, disabled, endIcon, startIcon, tooltip, ...rest } = props;
  const { classes } = useStyles();

  return (
    <Tooltip
      title={tooltip || ''}
      placement="top"
    >
      <span>
        <Button
          variant="contained"
          color="primary"
          {...rest}
          disabled={disabled || isSubmitting}
          endIcon={isSubmitting ? null : endIcon}
          startIcon={isSubmitting ? null : startIcon}
        >
          {
          isSubmitting
            ? (
              <CircularProgress
                size={24}
                className={classes.submitProgress}
                classes={{ circle: classes.submitProgressCircle }}
              />
            )
            : children
        }
        </Button>
      </span>
    </Tooltip>
  );
};

SubmitButton.defaultProps = {
  type: 'submit',
};

export default SubmitButton;
