
const userFullName = (user?: Partial<guesthouse.User>, useCompany?: boolean): string => {
  if (user) {
    const { firstname, lastname, company } = user;

    if (useCompany && company) {
      return company;
    }

    return firstname + ' ' + lastname;
  }

  return '';
};

export default userFullName;
