import Box from '@mui/material/Box';
import formatCurrency from 'format-currency';
import React, { useMemo } from 'react';

import { useStyles } from '../../utils/makeStyles';
import { offerPrice } from '../../utils/offerPrice';

interface ProductOfferProps {
  offer: guesthouse.ProductOffer | guesthouse.ProductOffer[];
  quantity?: number;
  showOriginal?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

interface ProductOfferSingleProps {
  offer: guesthouse.ProductOffer;
  quantity?: number;
  showOriginal?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

interface ProductOfferMultipleProps {
  offers: guesthouse.ProductOffer[];
  quantity?: number;
  showOriginal?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const ProductOfferSingle: React.FC<ProductOfferSingleProps> = (props) => {
  const { quantity, showOriginal } = props;
  const { salePrice, originalPrice } = offerPrice(props.offer);
  const { theme } = useStyles();

  return (
    <Box
      className={props.className}
      style={props.style}
      display="flex"
      alignItems="center"
    >
      {formatCurrency(salePrice * quantity, { format: '%s%v', symbol: '$' })}
      {
        salePrice < originalPrice && showOriginal && (
          <>
            {' '}
            <span style={{ marginLeft: theme.spacing(.5), textDecoration: 'line-through', fontSize: '.9em', lineHeight: '.9em', }}>
              {formatCurrency(originalPrice * quantity, { format: '%s%v', symbol: '$' })}
            </span>
          </>
        )
      }
    </Box>
  );
};

ProductOfferSingle.defaultProps = {
  quantity: 1,
  showOriginal: true,
};

function lowestOffer(offers: guesthouse.ProductOffer[] = []): guesthouse.ProductOffer | null {
  let lowest = null;

  for (const offer of offers) {
    if (!lowest) {
      lowest = offer;
    }

    const { salePrice } = offerPrice(offer);
    const { salePrice: currentLowestPrice } = offerPrice(lowest);

    if (salePrice < currentLowestPrice) {
      lowest = offer;
    }
  }

  return lowest;
}


export function highestOffer(offers: guesthouse.ProductOffer[] = []): guesthouse.ProductOffer | null {
  let highest = null;

  for (const offer of offers) {
    if (!highest) {
      highest = offer;
    }

    const { salePrice } = offerPrice(offer);
    const { salePrice: currentHighestPrice } = offerPrice(highest);

    if (salePrice > currentHighestPrice) {
      highest = offer;
    }
  }

  return highest;
}

function useLowestOffer(offers: guesthouse.ProductOffer[] = []): guesthouse.ProductOffer | null {
  return useMemo(() => lowestOffer(offers), [offers]);
}

function useHighestOffer(offers: guesthouse.ProductOffer[] = []): guesthouse.ProductOffer | null {
  return useMemo(() => highestOffer(offers), [offers]);
}

const ProductOfferMultiple: React.FC<ProductOfferMultipleProps> = (props) => {
  const { offers, quantity, showOriginal } = props;
  const highestOffer = useHighestOffer(offers);
  const { theme } = useStyles();

  if (highestOffer) {
    const { salePrice, originalPrice } = offerPrice(highestOffer);

    return (
      <Box
        className={props.className}
        style={props.style}
        display="flex"
        alignItems="center"
      >
        From
        {' '}
        {formatCurrency(salePrice * quantity, { format: '%s%v', symbol: '$' })}
        {
          salePrice < originalPrice && showOriginal && (
            <>
              {' '}
              <span style={{ marginLeft: theme.spacing(.5), textDecoration: 'line-through', fontSize: '.9em', lineHeight: '.9em', }}>
                {formatCurrency(originalPrice * quantity, { format: '%s%v', symbol: '$' })}
              </span>
            </>
          )
        }
      </Box>
    );
  }

  return null;
};

ProductOfferMultiple.defaultProps = {
  quantity: 1,
  showOriginal: true,
};

const ProductOffer: React.FC<ProductOfferProps> = (props) => {
  const { offer, quantity, showOriginal, className, style } = props;

  if (Array.isArray(offer) && offer.length > 0) {
    return (
      <ProductOfferMultiple
        offers={offer}
        quantity={quantity}
        showOriginal={showOriginal}
        className={className}
        style={style}
      />
    );
  }

  if (offer && !Array.isArray(offer)) {
    return (
      <ProductOfferSingle
        offer={offer}
        quantity={quantity}
        showOriginal={showOriginal}
        className={className}
        style={style}
      />
    );
  }

  return null;
};

ProductOffer.defaultProps = {
  quantity: 1,
  showOriginal: true,
};

export { lowestOffer, useHighestOffer,useLowestOffer };


export default ProductOffer;
