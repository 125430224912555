import PhoneNumber from 'awesome-phonenumber';
import * as yup from 'yup';

declare module 'yup' {
  export interface StringSchema {
    phone(countryCode?: string): StringSchema;
    instagramHandle(handle?: string): StringSchema;
  }
}

export const instagramHandle = async (value) => {
  if (!value) {
    return true;
  }
  try {
    // Source: https://blog.jstassen.com/2016/03/code-regex-for-instagram-username-and-hashtags/
    const instagramHandleRegEx = /[A-Za-z0-9_](?:(?:[A-Za-z0-9_]|(?:\.(?!\.))){0,28}(?:[A-Za-z0-9_]))?/;
    const instagramHandleMatches = value.match(instagramHandleRegEx);

    if (instagramHandleMatches && instagramHandleMatches[0] === instagramHandleMatches.input) {
      return true;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

yup.addMethod(yup.string, 'instagramHandle', function () {
  return this.test('instagramHandle', 'Instagram handle is not valid', instagramHandle);
});

yup.addMethod(yup.string, 'phone', function () {
  return this.test('phone', 'Phone number is not valid', async value => {
    if (!value) {
      return true;
    }
    try {
      const pn = new PhoneNumber(value, 'US');

      return pn.isValid();
    } catch (e) {
      return false;
    }
  });
});

export default yup;
