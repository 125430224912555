import CartContext from '@context/CartContext';
import calculateCartTotals from '@utils/calculateCartTotals';
import { useContext, useEffect, useState } from 'react';

type UseCartOptions = {
  taxTotal?: number;
}

type UseCartData = guesthouse.CartTotals & {
  loading: boolean;
  error?: boolean | Error;
}

function useCart(options: UseCartOptions = {}): UseCartData {
  const cartContext = useContext<CartContext>(CartContext);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<Error | undefined>();
  const [cartTotals, setCartTotals] = useState<guesthouse.CartTotals>();

  const {
    taxTotal = 0,
  } = options;

  useEffect(() => {
    setError(undefined);
    setLoading(true);

    calculateCartTotals({
      products: cartContext.products,
      coupon: cartContext.coupon,
      taxTotal,
    })
      .then((totals) => {
        setCartTotals(totals);
        setLoading(false);
      })
      .catch((e) => {
        setError(e);
        setLoading(false);
      });
  }, [cartContext, taxTotal]);


  return {
    loading,
    error,
    quantity: cartTotals?.quantity || 0,
    products: cartTotals?.products || [],
    sale: cartTotals?.sale || 0,
    subtotal: cartTotals?.subtotal || 0,
    shippingTotal: cartTotals?.shippingTotal || 0,
    taxTotal: cartTotals?.taxTotal || 0,
    total: cartTotals?.total || 0,
    saleCoupon: cartTotals?.saleCoupon || 0,
    saleCouponShipping: cartTotals?.saleCouponShipping || 0,
    coupon: cartTotals?.coupon,
    couponDiscount: cartTotals?.couponDiscount,
  };
}

export default useCart;
