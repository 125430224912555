import CONFIG from '@config';

const makeStoreUrl = (url = '') => {
  let protocol = 'https://';

  if (CONFIG.environment === 'local') {
    protocol = 'http://';
  }

  return new URL(url, protocol + CONFIG.hosts.store).toString();
};

export default makeStoreUrl;
