import makeStyles from '@ui/utils/makeStyles';
import rgba from 'polished/lib/color/rgba';

import { FancyInputProps } from './FancyInput.props';

type StyleProps = Pick<FancyInputProps, 'align'>

export default makeStyles<StyleProps>()((theme, { align }) => ({
  inputWrapper: {
    background: theme.palette.common.white,
    borderRadius: 10,
    height: 60,
    width: '100%',
    maxWidth: 500,
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transition: theme.transitions.create('box-shadow', { duration: 250 }),
    boxShadow: `0px 0px 0px ${rgba(theme.palette.tertiary.main, 0)}`,
    '&:focus-within': {
      boxShadow: `0px 0px 10px ${rgba(theme.palette.tertiary.main, .5)}`,
    },
  },
  input: {
    border: 0,
    // border: '1px solid red',
    fontFamily: theme.gh_vars.mabryMono,
    flex: 1,
    marginLeft: align === 'center' ? 54 : 0,
    fontSize: 14,
    lineHeight: 1.5,
    textAlign: align,
    width: '100%',
    '&:focus': {
      outline: 0,
    },
    '&.MuiInputBase-inputAdornedStart': {
      marginLeft: 0,
    }
  },
  autoComplete: {
    width: '100%',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginBottom: 0
    },
  },
  startAdornment: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(2)
    },
    '& path': {
      fill: '#666',
    }
  },
  numberInput: {
    marginLeft: align === 'center' ? 64 : theme.spacing(3),
    marginRight: 10,
  },
  locationInput: {
    marginLeft: `${theme.spacing(1)} !important`,
    marginRight: `${theme.spacing(1)} !important`,
    [theme.breakpoints.up('md')]: {
      marginLeft: `${theme.spacing(2)} !important`,
      marginRight: `${theme.spacing(2)} !important`,
    },
    textOverflow: 'ellipsis !important',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  unitInput: {
    height: 50,
    paddingLeft: theme.spacing(3),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: '100%',
      width: '40%',
      paddingLeft: 0
    }
  },
  endAdornment: {
    right: '10px !important',
    background: 'white',
  },
  button: {
    border: 0,
    fill: theme.palette.common.white,
    padding: 5,
    marginRight: 7,
    borderRadius: 5,
    '&:disabled': {
      background: rgba(theme.palette.common.black, .7),
      color: theme.palette.common.white,
      border: '1px solid transparent',
    },
  },
  icon: {
    fill: theme.palette.common.white,
    width: 25,
    height: 'auto',
    '& path': {
      fill: theme.palette.common.white,
    }
  }
}));
