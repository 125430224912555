function center(str: string, len: number) {
  const padding = Math.floor((len - str.length) / 2);
  let padded = str;

  padded = padded.padStart(padded.length + padding, ' ');
  padded = padded.padEnd(padded.length + padding, ' ');

  return padded;
}

export default function brandLogger(ref = '', sha = '') {
  if (!ref && !sha) {
    return;
  }
  
  try {
    const lines = [
      String.raw`      ___          ___          ___          ___                             ___          ___          ___          ___          ___     `,
      String.raw`     /  /\        /__/\        /  /\        /  /\        ___                /\__\        /\  \        /\__\        /\  \        /\  \    `,
      String.raw`    /  /:/_       \  \:\      /  /:/_      /  /:/_      /  /\              /:/  /       /::\  \      /:/  /       /::\  \      /::\  \   `,
      String.raw`   /  /:/ /\       \  \:\    /  /:/ /\    /  /:/ /\    /  /:/             /:/__/       /:/\:\  \    /:/  /       /:/\ \  \    /:/\:\  \  `,
      String.raw`  /  /:/_/::\  ___  \  \:\  /  /:/ /:/_  /  /:/ /::\  /  /:/             /::\  \ ___  /:/  \:\  \  /:/  /  ___  _\:\~\ \  \  /::\~\:\  \ `,
      String.raw` /__/:/__\/\:\/__/\  \__\:\/__/:/ /:/ /\/__/:/ /:/\:\/  /::\            /:/\:\  /\__\/:/__/ \:\__\/:/__/  /\__\/\ \:\ \ \__\/:/\:\ \:\__\ `,
      String.raw` \  \:\ /~~/:/\  \:\ /  /:/\  \:\/:/ /:/\  \:\/:/~/:/__/:/\:\           \/__\:\/:/  /\:\  \ /:/  /\:\  \ /:/  /\:\ \:\ \/__/\:\~\:\ \/__/`,
      String.raw`  \  \:\  /:/  \  \:\  /:/  \  \::/ /:/  \  \::/ /:/\__\/  \:\               \::/  /  \:\  /:/  /  \:\  /:/  /  \:\ \:\__\   \:\ \:\__\  `,
      String.raw`   \  \:\/:/    \  \:\/:/    \  \:\/:/    \__\/ /:/      \  \:\              /:/  /    \:\/:/  /    \:\/:/  /    \:\/:/  /    \:\ \/__/  `,
      String.raw`    \  \::/      \  \::/      \  \::/       /__/:/        \__\/             /:/  /      \::/  /      \::/  /      \::/  /      \:\__\    `,
      String.raw`     \__\/        \__\/        \__\/        \__\/                           \/__/        \/__/        \/__/        \/__/        \/__/    `,
    ];

    const str = `
\n
${lines.join('\n')}
\n
${center('Thanks for using Guest House.', lines[0].length)}
${center('We\'re hiring! Checkout https://guesthouseshop.com/careers for more info.', lines[0].length)}
\n
${ref && center(`${ref || '0'}`, lines[0].length)}
${sha && center(`${sha || '0'}`, lines[0].length)}
\n
    `;
  
    console.log(str);
  } catch (e) {
    console.error(e);
  }
}
