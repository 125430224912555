import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  productContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(3),
    borderBottom: '1px dashed #979797',
    paddingBottom: theme.spacing(2),
    alignItems: 'flex-start'
  },
  productImageCell: {
    width: 100,
    paddingLeft: 0,
  },
  productImageContainer: {
    width: 64,
    height: 64,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'flex-end',
    position: 'relative',
    marginRight: theme.spacing(2),
    '& img': {
      borderRadius: theme.shape.borderRadius,
      overflow: 'hidden',
      objectFit: 'contain',
      width: '100%',
      height: 'auto',
      position: 'absolute',
      top: '50%',
      left: 0,
    }
  },
  productDetailsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative'
  },
  productTextPrimary: {
    cursor: 'pointer',
    fontSize: 14,
    display: 'block',
    lineHeight: 1.25,
    marginBottom: theme.spacing(0.5),
  },
  productTextSecondary: {
    color: theme.palette.text.secondary,
    fontSize: 12,
    lineHeight: 1.25,
    maxWidth: '50%',
    fontFamily: theme.gh_vars.circular
  },
  quantitySelect: {
    fontSize: 12,
    position: 'relative',
    left: -10,
  }
}));
