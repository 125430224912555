import createCache from '@emotion/cache';
import { EmotionCache } from '@emotion/react';

let muiCache: EmotionCache | undefined = undefined;

export const createMuiCache = () => {
  if (muiCache) {
    return muiCache;
  }

  muiCache = createCache({ 'key': 'mui', 'prepend': true });
  return muiCache;
};
