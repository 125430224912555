import Sentry from '@integrations/Sentry';
import _404 from '@pages/404';
import React from 'react';

import Error from './Error';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}
class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  state = {
    hasError: false,
    error: null,
  };

  static getDerivedStateFromError(error) {
    return { 
      hasError: true,
      error: error,
    };
  }

  componentDidCatch(error) {
    Sentry.captureException(error);
  }

  render() {
    const { hasError, error } = this.state;

    
    if (hasError) {
      if (error.message === 'not-found') {
        return (<_404 />);
      }

      return (
        <Error error={error} />
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
