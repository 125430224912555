import { auth, database } from '@app/firebase';
import Sentry from '@integrations/Sentry';
import { signInAnonymously, User as FirebaseUser } from 'firebase/auth';
import { collection, doc, setDoc } from 'firebase/firestore';

/**
 * Return current Firebase user, or sign-in anonymously
 */
export default async function getFirebaseUser() : Promise<FirebaseUser | void> {
  if (!auth.currentUser) {
    return signInAnonymously(auth)
      .then(async ({ user }) => {
        // Create anon cart
        await setDoc(
          doc(collection(database, 'carts'), user.uid),
          { user: user.uid, products: {}, anonymous: true }
        );

        return user;
      })
      .catch((e) => {
        Sentry.captureException(e);
      });
  }

  return auth.currentUser;
}
