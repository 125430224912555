import { offerPrice } from '@ui/utils/offerPrice';
import userFullName from '@ui/utils/userFullName';
import isEmpty from 'lodash/isEmpty';

export const productToSegmentEventData = (product: Partial<guesthouse.Product>, offer: Partial<guesthouse.ProductOffer> = {}, quantity = 1) : segment.Product => {
  if (isEmpty(offer)) {
    return {
      name: product.title,
      product_id: product.docID,
      brand: userFullName(product.owner, true),
      quantity
    };
  }
  const { salePrice } = offerPrice(offer);
  const price: number = salePrice * quantity;

  return {
    name: product.title,
    product_id: product.docID,
    brand: userFullName(product.owner, true),
    price,
    quantity
  };
};

export const homeToSegmentEventData = (home: Partial<guesthouse.Home>) : segment.HomeClicked => {
  return {
    id: home.docID,
    name: home.address,
    item_category: 'homes',
    event_category: 'homes'
  };
};

export const userToSegmentEventData = (user: Partial<guesthouse.User>) => {
  return {
    id: user.docID,
    name: userFullName(user, true),
    item_category: 'makers',
  };
};
