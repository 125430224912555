import Typography from '@mui/material/Typography';
import React from 'react';

const CartZeroMessage: React.FC = () => {
  // const { theme } = useStyles();

  return (
    <>
      <Typography>
        Your cart is currently empty.
      </Typography>

      {/* <Button
        style={{
          marginTop: theme.spacing(3),
          paddingLeft: theme.spacing(3),
          paddingRight: theme.spacing(3)
        }}
        component={ButtonLink}
        href="/shop"
        variant="contained"
        color="primary"
      >
        Start Shopping
      </Button> */}
    </>
  );
};

export default CartZeroMessage;
