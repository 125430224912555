type CheckRolePermissions = (roles: guesthouse.Roles) => boolean;

export type AllowedRoles = Array<string | CheckRolePermissions>;

export default function checkRoles(allowedRoles: AllowedRoles = [], userRoles: guesthouse.Roles = {}) : boolean {
  const authRoles = userRoles;
  const roles = Object.keys(authRoles).filter(k => authRoles[k]);

  const fns = allowedRoles.filter(f => typeof f === 'function');

  for (const fn of fns) {
    if (typeof fn === 'function' && fn(authRoles)) {
      return true;
    }
  }

  return roles.some(r=> allowedRoles.includes(r));
}
