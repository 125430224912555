import { createTheme, responsiveFontSizes } from '@mui/material/styles';
import { Shadows } from '@mui/material/styles/shadows';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import rgba from 'polished/lib/color/rgba';
import transparentize from 'polished/lib/color/transparentize';

export const BLACK = '#080808';
export const WHITE = '#fff';
export const GREEN_LIGHT = '#d3e0e2';
export const GREEN_DARK = '#83a5b0';
export const GREEN_EXTRA_LIGHT = 'rgba(211, 224, 226, 0.25)';
export const GREY = '#666666';
export const ERROR = '#a3403d';
export const ERROR_ALT = '#C4544C';
export const ORANGE = '#AD6556';
export const ORANGE_ALT = '#EB8853';
export const YELLOW = '#EBBC76';
export const YELLOW_ALT = '#DC9A5C';
export const BACKGROUND = '#F7F7F7';
export const DARK_ONYX = '#080808';
export const DARK_CHARCOAL = '#343434';
export const DARK_TEXT = '#666666';
export const DARK_DIV = '#DDDDDD';
export const BLUE_MIST = '#F7F7F7';
export const BROWN_STONE = '#C1BEBD';


export const defaultTheme = createTheme();

export const FONT_PRIMARY = 'CircularXX, sans-serif';
export const FONT_SECONDARY = 'CircularXX, sans-serif';

const BORDER_RADIUS = 10;

export const BUTTON_TRANSITION = defaultTheme.transitions.create(['background-color', 'color', 'fill'], { duration: 200, delay: 0 });
export const TEXT_TRANSITION = defaultTheme.transitions.create(['color'], { duration: 200, delay: 0 });

export default responsiveFontSizes(createTheme({
  shadows: Array(25).fill('none') as Shadows,
  palette: {
    primary: {
      main: GREEN_DARK,
      light: GREEN_LIGHT,
      contrastText: BLACK,
    },
    secondary: {
      main: WHITE,
      contrastText: BLACK,
    },
    tertiary: {
      main: '#9fb9bc',
      light: '#e5e8eb',
      contrastText: BLACK,
    },
    pink: {
      main: '#ede4e2',
      light: '#f6f1f0',
      dark: '#cb8e83',
      contrastText: WHITE,
    },
    white: {
      main: WHITE,
      light: WHITE,
      dark: WHITE,
      contrastText: BLACK,
    },
    background: {
      paper: WHITE,
      default: WHITE,
    },
    common: {
      black: BLACK,
      white: WHITE,
    },
    error: {
      main: ERROR,
    },
    action: {
      disabledBackground: '#e8e8e8',
    },
    text: {
      primary: BLACK,
      secondary: GREY,
    },
    dark: {
      light: DARK_DIV,
      main: DARK_CHARCOAL,
      dark: DARK_ONYX,
      contrastText: DARK_TEXT,
    }
  },
  components: {
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          color: WHITE,
          backgroundColor: ORANGE,
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        iconSmall: {
          width: 18,
          height: 18,
        },
        label: {
          lineHeight: 1,
        }
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: BLACK,
          transition: TEXT_TRANSITION,
        },
        underlineHover: {
          textDecoration: 'underline',
        }
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontFamily: FONT_PRIMARY,
        }
      }
    },
    MuiMenuItem: {
      defaultProps: {
        dense: true,
      }
    },
    MuiIconButton: {
      variants: [
        {
          props: {
            size: 'xs',
          },
          style: {
            fontSize: 12,
            padding: 4,
            '& svg': {
              width: '.65em',
              height: '.65em',
            }
          }
        }
      ]
    },
    MuiButton: {
      variants: [
        {
          props: {
            color: 'white',
          },
          style: {
            transition: BUTTON_TRANSITION,
            color: BLACK,
            fill: BLACK,
            backgroundColor: WHITE,
            border: `1px solid ${WHITE}`,
            '&:hover': {
              color: BLACK,
              fill: BLACK,
              backgroundColor: WHITE,
            },
          }
        },
        {
          props: {
            color: 'white',
            variant: 'outlined'
          },
          style: {
            transition: BUTTON_TRANSITION,
            color: WHITE,
            fill: WHITE,
            backgroundColor: 'transparent',
            border: `1px solid ${WHITE}`,
            '&:hover': {
              color: WHITE,
              fill: WHITE,
              backgroundColor: transparentize(.8, WHITE),
              border: `1px solid ${WHITE}`,
            }
          }
        },
        {
          props: {
            color: 'white',
            variant: 'text'
          },
          style: {
            transition: BUTTON_TRANSITION,
            color: WHITE,
            fill: WHITE,
            backgroundColor: 'transparent',
            border: 0,
            '&:hover': {
              color: WHITE,
              fill: WHITE,
              backgroundColor: transparentize(.8, WHITE),
              border: 0,
            }
          }
        },
        {
          props: {
            size: 'xs',
          },
          style: {
            height: 28,
            borderRadius: 5,
            fontSize: 12,
          }
        },
      ],
      styleOverrides: {
        root: {
          height: 48,
          borderRadius: 10,
          lineHeight: 1,
          textTransform: 'none',
          fontFamily: FONT_PRIMARY,
          '&.Mui-disabled': {
            color: WHITE,
            fill: WHITE,
            backgroundColor: rgba(BLACK, .5),
            borderColor: 'transparent',
          }
        },
        contained: {
          transition: BUTTON_TRANSITION,
          color: BLACK,
          fill: BLACK,
          backgroundColor: GREEN_LIGHT,
          '&:hover': {
            color: WHITE,
            fill: WHITE,
            backgroundColor: GREEN_DARK,
          }
        },
        containedPrimary: {
          transition: BUTTON_TRANSITION,
          color: BLACK,
          fill: BLACK,
          backgroundColor: GREEN_LIGHT,
          border: `1px solid ${BLACK}`,
          '&:hover': {
            color: WHITE,
            fill: WHITE,
            backgroundColor: GREEN_DARK,
          }
        },
        containedSecondary: {
          transition: BUTTON_TRANSITION,
          color: WHITE,
          fill: WHITE,
          backgroundColor: BLACK,
          border: `1px solid ${BLACK}`,
          '&:hover': {
            color: WHITE,
            fill: WHITE,
            backgroundColor: BLACK,
          },
        },
        textPrimary: {
          transition: BUTTON_TRANSITION,
          color: GREEN_DARK,
          '&:hover': {
            backgroundColor: GREEN_EXTRA_LIGHT,
          }
        },
        outlinedPrimary: {
          transition: BUTTON_TRANSITION,
          color: BLACK,
          fill: BLACK,
          backgroundColor: 'transparent',
          border: `1px solid ${BLACK}`,
          '&:hover': {
            color: BLACK,
            fill: BLACK,
            backgroundColor: GREEN_LIGHT,
            border: `1px solid ${BLACK}`,
          }
        },
        outlinedSecondary: {
          transition: BUTTON_TRANSITION,
          color: GREEN_DARK,
          backgroundColor: 'transparent',
          border: `1px solid ${GREEN_DARK}`,
          '&:hover': {
            color: BLACK,
            fill: BLACK,
            backgroundColor: GREEN_LIGHT,
            border: `1px solid ${BLACK}`,
          }
        },
        textSecondary: {
          transition: BUTTON_TRANSITION,
          color: BLACK,
          fill: BLACK,
          '&:hover': {
            backgroundColor: GREEN_EXTRA_LIGHT,
          }
        },
        sizeSmall: {
          height: 36,
        },
        sizeMedium: {
          height: 48,
        },
        sizeLarge: {
          height: 60,
          fontSize: 18,
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: -4,
        },
        label: {
          fontSize: 14,
          marginLeft: 4,
        }
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: FONT_SECONDARY,
          fontWeight: 500,
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: 14,
          '&.Mui-focused': {
            color: BLACK,
          }
        },
        shrink: {
          color: BLACK,
        },
      },
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          fontFamily: FONT_SECONDARY,
          color: GREY,
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h3Alt: 'h3',
          h4Alt: 'h4',
          h5Alt: 'h5',
          h6Alt: 'h6',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          transition: 'inherit',
        }
      },
    },
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent !important',
          borderRadius: '10px !important',
        },
        input: {
          backgroundColor: `${WHITE} !important`,
          borderRadius: '10px !important',
          border: '1px solid #efefef',
          transition: defaultTheme.transitions.create('box-shadow', { duration: 250 }),
          boxShadow: `0px 0px 0px ${rgba('#9fb9bc', 0)}`,
          '&:focus-within': {
            boxShadow: `0px 0px 10px ${rgba('#9fb9bc', .5)}`,
          },
          color: BLACK,
          '&:before': {
            borderBottom: 'none',
          },
          '&:after': {
            borderBottom: 'none',
          },
          '&[aria-invalid=true]': {
            borderColor: ERROR,
          }
        },
        underline: {
          '&:before': {
            borderBottomStyle: 'hidden !important',
            borderBottom: 'none',
          },
          '&:hover:before': {
            borderBottom: 'none',
          },
          '&:after': {
            borderBottom: 'none',
          }
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontFamily: FONT_SECONDARY,
          fontWeight: 400,
          fontSize: 14,
        },
        adornedStart: {
          '& > svg': {
            width: 24,
            height: 24,
          }
        },
        input: {
          '&::placeholder': {
            color: '#666',
            opacity: .7,
          },
          '&:-moz-placeholder': {
            color: '#666',
            opacity: .7,
          },
          '&::-webkit-input-placeholder': {
            color: '#666',
            opacity: .7,
          },
        },

      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          fontFamily: FONT_PRIMARY,
          alignItems: 'center',
          fontSize: 14,
        },
        filledInfo: {
          fill: WHITE,
          backgroundColor: BLACK,
        }
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'rgba(0, 0, 0, .75)'
        }
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          color: BLACK,
        }
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          display: 'flex',
          alignItems: 'center',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: 3,
        }
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          '& > h2': {
            paddingBottom: defaultTheme.spacing(2),
            marginBottom: defaultTheme.spacing(1),
            borderBottom: '1px solid #DDDDDD',
          },
          padding: defaultTheme.spacing(2, 3, 1),
        }
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          width: `calc(100% - ${defaultTheme.spacing(6)})`,
          margin: defaultTheme.spacing(4, 3),
        },
        paperFullScreen: {
          width: '100%',
          margin: defaultTheme.spacing(0),
        }
      }
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(1, 3),
        }
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: defaultTheme.spacing(0, 3, 3),
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          border: 'none',
          margin: defaultTheme.spacing(1.5, 0),
          padding: defaultTheme.spacing(1, 0),
          boxShadow: 'none',
          '&.Mui-expanded': {
            margin: 0,
          },
          '&.Mui-expanded:before': {
            opacity: 'inherit !important',
          },
          '&.Mui-disabled': {
            background: '#fff',
          },
        },
        rounded: {
          borderRadius: BORDER_RADIUS,
        }
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
          '&.Mui-expanded': {
            minHeight: 'inherit',
          }
        },
        content: {
          '&.Mui-expanded': {
            margin: '12px 0',
          }
        },
        expandIconWrapper: {
          '&.Mui-expanded': {
            transform: 'rotateX(180deg)',
            transition: defaultTheme.transitions.create('transform', { duration: 350 })
          }
        }
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        }
      },
    },
    MuiSkeleton: {
      defaultProps: {
        animation: 'pulse',
        variant: 'rectangular',
      },
      styleOverrides: {
        text: {
          borderRadius: BORDER_RADIUS,
        },
        rectangular: {
          borderRadius: BORDER_RADIUS,
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: 58,
          height: 40,
          padding: 10,
        },
        switchBase: {
          padding: 14,
          '&.Mui-checked': {
            transform: 'translateX(16px)',
            '& + .MuiSwitch-track': {
              color: 'white',
              opacity: 1,
              border: 'none',
            },
          },
        },
        thumb: {
          width: 12,
          height: 12,
          color: WHITE
        },
        track: {
          borderRadius: BORDER_RADIUS,
          border: '#A9A9A9',
          backgroundColor: '#A9A9A9',
          opacity: 1,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          border: 'none'
        }
      }
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          '& > *': {
            marginTop: defaultTheme.spacing(2),
            justifyContent: 'center',
            display: 'flex',
            [defaultTheme.breakpoints.up('md')]: {
              justifyContent: 'flex-end'
            }
          }
        }
      },
    },
    MuiDatePicker: {
      defaultProps: {
        desktopModeMediaQuery: defaultTheme.breakpoints.up('md'),
      },
    },
  },
  typography: {
    fontFamily: FONT_PRIMARY,
    display1: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 500,
      lineHeight: 1.16,
      display: 'block',
      fontSize: 48,
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 96,
      }
    },
    display2: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 500,
      lineHeight: 1.16,
      display: 'block',
      fontSize: 36,
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 72,
      }
    },
    //historical value, migrating to display2
    display2Alt: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 500,
      lineHeight: 1.16,
      display: 'block',
      fontSize: 40,
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 84,
      }
    },
    display3: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 500,
      lineHeight: 1.16,
      display: 'block',
      fontSize: 30,
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 60,
      }
    },
    display4: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 500,
      lineHeight: 1.16,
      display: 'block',
      fontSize: 12,
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 24,
      }
    },
    //historical value, migrating to display4
    display4Alt: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 500,
      lineHeight: 1.16,
      display: 'block',
      fontSize: 32,
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 60,
      }
    },
    h1: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 500,
      lineHeight: 1.16,
      fontSize: 48,
    },
    h2: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 600,
      lineHeight: 1.2,
      fontSize: 36,
      [defaultTheme.breakpoints.up('md')]: {
        fontSize: 60,
      }
    },
    h3: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 600,
      lineHeight: 1.16,
      fontSize: 24,
    },
    //historical value, migrating to h3
    h3Alt: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 600,
      lineHeight: 1.16,
      fontSize: 28,
    },
    h4: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 600,
      lineHeight: 1.2,
      fontSize: 18,
    },
    //historical value, migrating to h4
    h4Alt: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 600,
      lineHeight: 1.2,
      fontSize: 24,
    },
    h5: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 600,
      lineHeight: 1.33,
      fontSize: 16,
    },
    //historical value, migrating to h5
    h5Alt: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 600,
      lineHeight: 1.33,
      fontSize: 18,
    },
    h6: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 600,
      lineHeight: 1.33,
      fontSize: 14,
    },
    //historical value, migrating to h6
    h6Alt: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 600,
      lineHeight: 1.33,
      fontSize: 16,
    },
    subtitle1: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 600,
      lineHeight: 1.15,
      fontSize: 14,
    },
    subtitle2: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 600,
      lineHeight: 'auto',
      fontSize: 12,
    },
    bodyLarge: {
      fontFamily: FONT_SECONDARY,
      fontWeight: 400,
      lineHeight: 'auto',
      fontSize: 16,
    },
    bodyMedium: {
      fontFamily: FONT_SECONDARY,
      fontWeight: 400,
      lineHeight: 1.6,
      fontSize: 12,
    },
    bodySmall: {
      fontFamily: FONT_SECONDARY,
      fontWeight: 400,
      lineHeight: 1.4,
      fontSize: 8,
    },
    body1: {
      fontFamily: FONT_SECONDARY,
      fontWeight: 400,
      lineHeight: 1.75,
      fontSize: 14,
    },
    body2: {
      fontFamily: FONT_SECONDARY,
      fontWeight: 400,
      lineHeight: 1.75,
      fontSize: 13,
    },
    body3: {
      fontFamily: FONT_SECONDARY,
      fontWeight: 400,
      lineHeight: 1.75,
      fontSize: 12,
    },
    button: {
      textTransform: 'none',
      fontFamily: FONT_SECONDARY,
      fontWeight: 500,
      lineHeight: 1.71,
      fontSize: 16,
    },
    buttonSmall: {
      textTransform: 'none',
      fontFamily: FONT_SECONDARY,
      fontWeight: 500,
      lineHeight: 1.71,
      fontSize: 14,
    },
    caption1: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 400,
      lineHeight: 'auto',
      fontSize: 12,
    },
    caption2: {
      fontFamily: FONT_PRIMARY,
      fontWeight: 400,
      lineHeight: 1.3,
      fontSize: 10,
    },
    overline: {
      textTransform: 'none',
      fontFamily: FONT_SECONDARY,
      fontWeight: 400,
      lineHeight: 1.6,
      fontSize: 14,
    },
    linkedText: {
      textTransform: 'none',
      fontFamily: FONT_PRIMARY,
      fontWeight: 400,
      lineHeight: 'auto',
      fontSize: 14,
    },
    linkedTextSmall: {
      textTransform: 'none',
      fontFamily: FONT_PRIMARY,
      fontWeight: 400,
      lineHeight: 'auto',
      fontSize: 12,
    },
  },
  shape: {
    borderRadius: BORDER_RADIUS,
  },
  gh_vars: {
    maxWidth: 1500,
    mabry: FONT_SECONDARY,
    circular: FONT_PRIMARY,
    defaultBackgroundColor: BACKGROUND,
  },
}));
