import { GlobalStyles } from '@mui/material';
import { useStyles } from '@ui/utils/makeStyles';
import React from 'react';

interface AppBackgroundColorProps {
  backgroundColor?: string;
  borderColor?: string;
  headerColor?: string;
}

const AppBackgroundColor: React.FC<AppBackgroundColorProps> = (props) => {
  const { backgroundColor, borderColor, headerColor } = props;
  const { theme } = useStyles();
  const DEFAULT_BG = theme.gh_vars.defaultBackgroundColor;

  return (
    <GlobalStyles
      styles={{
        body: {
          backgroundColor: `${backgroundColor ? backgroundColor : DEFAULT_BG} !important`,
          borderColor: `${borderColor ? borderColor : 'transparent'} !important`,
        },
        header: {
          backgroundColor: `${headerColor ? headerColor : backgroundColor ? backgroundColor : DEFAULT_BG} !important`,
        }
      }}
    />
  );
};

export default AppBackgroundColor;
