
import { database } from '@app/firebase';
import Sentry from '@integrations/Sentry';
import { offerPrice } from '@ui/utils/offerPrice';
import { collection, doc, getDoc, getDocs } from 'firebase/firestore';
import round from 'lodash.round';

export const makeCartProducts = async (products: guesthouse.CartProducts): Promise<guesthouse.CartProduct[]> => {
  if (products && Object.keys(products)) {
    const ids = Object.keys(products);

    const promises = [];

    for (const id of ids) {
      const { quantity, productID, offerID } = products[id];

      const getCartProduct = async (productID: string, offerID: string, quantity: number) => {
        try {
          const [product, offer] = await Promise.all([
            getDoc(doc(database, `products/${productID}`)).then(p => p.data() as guesthouse.Product),
            getDoc(doc(database, `products/${productID}/offers/${offerID}`)).then(p => p.data() as guesthouse.ProductOffer)
          ]);
  
          if (!product || !offer) {
            return;
          }
  
          delete product?.currentHomes;
          delete product?.pastHomes;
          delete product?.futureHomes;
  
          const cartProduct: guesthouse.CartProduct = {
            quantity,
            product,
            offer,
            discount: 0,
          };
  
          return cartProduct; 
        } catch (e) {
          Sentry.captureException(e);
        }
      };

      promises.push(getCartProduct(productID, offerID, quantity));
    }

    return await Promise.all(promises)
      .then(p => {
        return p.filter(Boolean)
          .sort((a, b) => a.product.title.localeCompare(b.product.title));
      });

  } else {
    return [];
  }
};

export const fetchCouponProducts = async (coupon: guesthouse.Coupon): Promise<{ productsIncluded: guesthouse.ProductLite[], productsExcluded: guesthouse.ProductLite[] }> => {
  let productsIncluded: guesthouse.ProductLite[] = [];
  let productsExcluded: guesthouse.ProductLite[] = [];

  if (coupon?.docID) {
    try {
      productsIncluded = await getDocs(
        collection(doc(collection(database, 'coupons'), coupon.docID), 'productsIncluded')
      )
        .then(({ docs }) => {
          if (docs?.length) {
            return docs.map(product => product.data() as guesthouse.ProductLite);
          }

          return [];
        });
    } catch (e) {
      Sentry.captureException(e);
    }
    try {
      productsExcluded = await getDocs(
        collection(doc(collection(database, 'coupons'), coupon.docID), 'productsExcluded')
      )
        .then(({ docs }) => {
          if (docs?.length) {
            return docs.map(product => product.data() as guesthouse.ProductLite);
          }

          return [];
        });
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  return {
    productsIncluded,
    productsExcluded
  };
};

export const calculateCartTotals = async (options: {
  products: guesthouse.CartProducts,
  coupon?: guesthouse.Coupon,
  taxTotal?: number,
}): Promise<guesthouse.CartTotals> => {
  const {
    products,
    coupon,
    taxTotal = 0
  } = options;

  const cartProducts = await makeCartProducts(products);
  const { productsIncluded, productsExcluded } = await fetchCouponProducts(coupon);

  // let cartPickupOnly = true;

  let cartSale = 0;
  let cartSubtotal = 0;
  let cartShippingTotal = 0;
  let cartTotal = 0;

  let cartTotalQuantity = 0;

  let couponShippingDiscount = 0;
  let couponDiscount = 0;

  const appliedProductDocIDs: string[] = [];
  const validProductDocIDs = productsIncluded.map(product => product.docID);
  const invalidProductDocIDs = productsExcluded.map(product => product.docID);

  for (const cartProduct of cartProducts) {
    const { quantity, offer, product } = cartProduct;
    const productApplied = appliedProductDocIDs.find(id => id === product.docID);
    let couponDiscountProduct = 0;
    let couponShippingDiscountProduct = 0;

    const { salePrice, originalPrice } = offerPrice(offer);

    const couponValidForProduct = (validProductDocIDs.includes(cartProduct.product?.docID) && !invalidProductDocIDs.includes(cartProduct.product?.docID));

    if (couponValidForProduct && coupon?.order_application === 'no_shipping' && !productApplied) {
      if (coupon.retail_discount_type === 'fixed') {
        couponDiscountProduct += coupon.retail_discount_amount;
      }
      if (coupon.retail_discount_type === 'percent') {
        couponDiscountProduct += salePrice * coupon.retail_discount_amount;
      }

      couponDiscount += round(couponDiscountProduct, 2);

      appliedProductDocIDs.push(product.docID);
    }


    if (salePrice < originalPrice) {
      cartSale += quantity * (originalPrice - salePrice);
    }

    cartTotalQuantity += quantity;
    cartSubtotal += quantity * originalPrice;

    if (offer && !offer.pickupOnly) {
      if (offer.shippingPricePerProduct) {
        cartShippingTotal += offer.shippingPricePerProduct * quantity;
      }

      if (offer.shippingPricePerOrder) {
        cartShippingTotal += offer.shippingPricePerOrder;
      }

      if (couponValidForProduct && coupon?.order_application === 'shipping_only' && !productApplied) {
        if (coupon.retail_discount_type === 'fixed') {
          if (offer?.shippingPricePerProduct) {
            couponShippingDiscountProduct += coupon.retail_discount_amount;
          }
          if (offer?.shippingPricePerOrder) {
            couponShippingDiscountProduct += coupon.retail_discount_amount;
          }
        }
        if (coupon.retail_discount_type === 'percent') {
          if (offer?.shippingPricePerProduct) {
            couponShippingDiscountProduct += offer.shippingPricePerProduct
              * coupon.retail_discount_amount;
          }
          if (offer?.shippingPricePerOrder) {
            couponShippingDiscountProduct += offer.shippingPricePerOrder * coupon.retail_discount_amount;
          }
        }

        couponShippingDiscount += round(couponShippingDiscountProduct, 2);

        appliedProductDocIDs.push(product.docID);
      }

      if (couponValidForProduct && coupon?.order_application === 'global' && !productApplied) {
        if (coupon.retail_discount_type === 'fixed') {
          couponDiscountProduct += coupon.retail_discount_amount;
        }
        if (coupon.retail_discount_type === 'percent') {
          if (offer?.shippingPricePerProduct) {
            couponDiscountProduct += (offer.shippingPricePerProduct)
              * coupon.retail_discount_amount;
          }
          if (offer?.shippingPricePerOrder) {
            couponDiscountProduct += (offer.shippingPricePerOrder) * coupon.retail_discount_amount;
          }

          couponDiscountProduct += salePrice * coupon.retail_discount_amount;
        }

        couponDiscount += round(couponDiscountProduct, 2);

        appliedProductDocIDs.push(product.docID);
      }
    }

    cartProduct.discount = round(couponDiscountProduct, 2);
  }

  if (coupon?.order_application === 'global' && !validProductDocIDs.length && !invalidProductDocIDs.length) {
    if (coupon.retail_discount_type === 'fixed') {
      couponDiscount += coupon.retail_discount_amount;
    }

    if (coupon.retail_discount_type === 'percent') {
      couponDiscount = ((cartSubtotal - cartSale + cartShippingTotal) * coupon.retail_discount_amount);
    }
  }

  if (coupon?.order_application === 'no_shipping' && !validProductDocIDs.length && !invalidProductDocIDs.length) {
    if (coupon.retail_discount_type === 'fixed') {
      couponDiscount += coupon.retail_discount_amount;
    }
    if (coupon.retail_discount_type === 'percent') {
      couponDiscount = ((cartSubtotal - cartSale) * coupon.retail_discount_amount);
    }
  }

  if (coupon?.order_application === 'shipping_only' && !validProductDocIDs.length && !invalidProductDocIDs.length) {
    if (coupon.retail_discount_type === 'fixed') {
      couponDiscount += coupon.retail_discount_amount;
    }
    if (coupon.retail_discount_type === 'percent') {
      couponDiscount = ((cartShippingTotal) * coupon.retail_discount_amount);
    }
  }

  if (couponShippingDiscount > cartShippingTotal) {
    couponShippingDiscount = cartShippingTotal;
  }

  if (coupon?.min_order_amount && cartSubtotal < coupon.min_order_amount) {
    couponDiscount = 0;
    couponShippingDiscount = 0;
  }

  couponDiscount += couponShippingDiscount;
  cartTotal = cartSubtotal - cartSale + cartShippingTotal + taxTotal - couponDiscount;

  return {
    sale: round(cartSale, 2),
    subtotal: round(cartSubtotal, 2),
    shippingTotal: round(cartShippingTotal, 2),
    taxTotal: round(taxTotal, 2),
    total: round(cartTotal, 2),
    quantity: cartTotalQuantity,
    products: cartProducts,
    saleCoupon: couponDiscount,
    saleCouponShipping: couponShippingDiscount,
    coupon: coupon,
    couponDiscount,
  };
};


export default calculateCartTotals;
