import UserContext from '@context/UserContext';
import React, { useContext } from 'react';

import checkRoles, { AllowedRoles } from '../../utils/checkRoles';


type ProtectedProps = {
  allowedRoles?: AllowedRoles;
  renderUnauthorized?: (isLoggedIn: boolean) => JSX.Element | string;
  children?: React.ReactNode;
}

/**
 * Role ['*'] - public route
 * Role ['@'] - all logged in users
 * Role ['!'] - only logged out users
 */
const Protected: React.FC<ProtectedProps> = (props) => {
  const { allowedRoles, children, renderUnauthorized } = props;
  const userContext = useContext<UserContext>(UserContext);
  const authRoles = userContext.roles || {};

  if (allowedRoles.includes('!') && !userContext.user) {
    return (
      <>
        {children}
      </>
    );
  }

  if (allowedRoles.includes('@') && userContext.user) {
    return (
      <>
        {children}
      </>
    );
  }

  if (allowedRoles.includes('*')) {
    return (
      <>
        {children}
      </>
    );
  }

  const authorized = checkRoles(allowedRoles, authRoles);

  if (authorized) {
    return (
      <>
        {children}
      </>
    );
  }

  if (renderUnauthorized) {
    return (
      <>
        {renderUnauthorized(!!userContext.user)}
      </>
    );
  }

  return null;
};

Protected.defaultProps = {
  allowedRoles: [],
};

export default Protected;
