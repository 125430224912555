import { database } from '@app/firebase';
import ButtonLink from '@components/button-link/ButtonLink';
import CartTotals from '@components/cart/CartTotals';
import CartZeroMessage from '@components/cart/CartZeroMessage';
import CartProduct from '@components/cart-product/CartProduct';
import ErrorBoundary from '@components/error/ErrorBoundary';
import Footer from '@components/footer/Footer';
import LoginRegister from '@components/login-register/LoginRegister';
import MobileMenuAlt from '@components/menus/MobileMenuAlt';
import config from '@config';
import CartContext from '@context/CartContext';
import UserContext from '@context/UserContext';
import useCart from '@hooks/useCart';
import Sentry from '@integrations/Sentry';
import { ContentBlock } from '@layouts/ContentBlocks';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import routes from '@routes/routes';
import Protected from '@ui/components/protected/Protected';
import QuantitySelect from '@ui/components/quantity-select/QuantitySelect';
import IconDelete from '@ui/icons/imaterial/base/Delete Forever.svg';
import IconX from '@ui/icons/imaterial/base/X.svg';
import slugOrID from '@ui/utils/slugOrID';
import userFullName from '@ui/utils/userFullName';
import { addToCart } from '@utils/cart';
import { collection, deleteField, doc, updateDoc } from 'firebase/firestore';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';

import useStyles from './App.style';

// this is to avoid a server-client hydration mismatch.
// HeaderAlt relies on client-side media queries, which default to false on the server.
// if they evaluate to true on the client, the client-side DOM does not match the
// server-side DOM, and we get a hydration error. to avoid that, we tell Next
// not to render it on the server, since the client-side media query is necessary
const HeaderAlt = dynamic(() => import('@components/header/HeaderAlt'), {
  ssr: false,
});

const App = ({ children }) => {
  const { classes, theme } = useStyles();

  const router = useRouter();
  const routeInfo = routes[router.pathname];
  const pageRoles = routeInfo ? routeInfo.roles : '*';
  const cartContext = useContext<CartContext>(CartContext);
  const userContext = useContext<UserContext>(UserContext);

  const { products } = useCart();

  const setDrawerOpen = (open: boolean) => cartContext.setContext(prev => ({ ...prev, cartDrawerOpen: open }));

  return (
    <>
      <MobileMenuAlt />

      <HeaderAlt />

      <SwipeableDrawer
        open={cartContext.cartDrawerOpen}
        anchor="right"
        onClose={() => setDrawerOpen(false)}
        onOpen={() => setDrawerOpen(true)}
      >
        <Box
          style={{
            padding: theme.spacing(15, 5, 5, 5)
          }}
          display="flex"
          flexDirection="column"
          height="100vh"
          overflow="hidden"
          maxWidth="440px"
          width="100vw"

        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            style={{ marginBottom: theme.spacing(2) }}
          >
            <Typography
              variant="h4Alt"
              component="div"
            >
              Your cart
              {products.length > 0 ? `, ${products.length} item${products.length > 1 ? 's' : ''}` : ''}
            </Typography>
            <IconButton
              data-test="close-cart"
              aria-label="Close cart"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDrawerOpen(false);
              }}
            >
              <IconX />
            </IconButton>
          </Box>

          {
            (products?.length) ? (
              <>
                <Box
                  flex="1 1 0%"
                  style={{
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    paddingRight: theme.spacing(1)
                  }}
                >
                  {
                    products && Object.keys(products).map((id) => {
                      const cartProduct = products[id];

                      return (
                        <CartProduct
                          key={id}
                          item={cartProduct.product}
                          offer={cartProduct.offer}
                          quantity={cartProduct.quantity}
                          href={`/shop/products/${slugOrID(cartProduct.product)}`}
                          textPrimary={cartProduct.product.title}
                          textSecondary={cartProduct.product.owner?.company}
                          renderAction={() => (
                            <>
                              <QuantitySelect
                                style={{ fontSize: 13, position: 'relative', left: -10 }}
                                count={cartProduct.quantity}
                                min={1}
                                max={cartProduct.offer?.dropship ? undefined : cartProduct.offer?.stockQuantity}
                                onUp={() => addToCart(cartContext, cartProduct.product, cartProduct.offer, 1)}
                                onDown={() => addToCart(cartContext, cartProduct.product, cartProduct.offer, -1)}
                              />

                              <Tooltip
                                title="Remove"
                                onClick={() => {
                                  updateDoc(
                                    doc(collection(database, 'carts'), userContext.user.uid),
                                    {
                                      [`products.${cartProduct.offer.docID}`]: deleteField()
                                    });

                                  const removeFromCartEventData: segment.Product = {
                                    name: cartProduct.product.title,
                                    price: cartProduct.quantity * cartProduct.offer.salePrice,
                                    product_id: cartProduct.product.docID,
                                    sku: cartProduct.product.sku,
                                    quantity: cartProduct.quantity,
                                    brand: userFullName(cartProduct.product.owner, true)
                                  };

                                  try {
                                    window.analytics?.track('Product Removed', removeFromCartEventData);
                                  } catch (e) {
                                    Sentry.captureException(e);
                                  }
                                }}
                              >
                                <IconButton
                                  size="medium"
                                  style={{ position: 'relative', right: -10 }}
                                >
                                  <IconDelete style={{ fill: '#000' }} />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        />
                      );
                    })
                  }
                  <CartTotals />

                </Box>

                <Box style={{ paddingTop: theme.spacing(1.5) }}>

                  <Button
                    fullWidth
                    component={ButtonLink}
                    href="/my/cart"
                    color="primary"
                    variant="outlined"
                    aria-label="View cart"
                    data-test="cart-drawer-view-cart"
                    style={{
                      marginBottom: theme.spacing(1.5)
                    }}
                    onClick={() => setDrawerOpen(false)}
                  >
                    View cart
                  </Button>

                  <Button
                    fullWidth
                    component={ButtonLink}
                    href="/checkout"
                    color="secondary"
                    variant="contained"
                    aria-label="Checkout"
                    data-test="cart-drawer-checkout"
                    onClick={() => setDrawerOpen(false)}
                  >
                    Checkout
                  </Button>
                </Box>
              </>
            ) : (
              <CartZeroMessage />
            )
          }
        </Box>
      </SwipeableDrawer>

      <main className={classes.main}>
        <ErrorBoundary>
          <Protected
            allowedRoles={pageRoles}
            renderUnauthorized={() => {
              return (
                <ContentBlock maxWidth={600}>
                  <Typography
                    variant="h3Alt"
                    component="h1"
                    style={{ marginBottom: theme.spacing(3) }}
                  >
                    Create an account or log in to save items, checkout and more with Guest House.
                  </Typography>

                  <LoginRegister />
                </ContentBlock>
              );
            }}
          >
            {children}
          </Protected>
        </ErrorBoundary>
      </main>

      <Footer />

      {['stage', 'development'].indexOf(config.environment) > -1 ? (
        <div className={classes.appEnvironmentNotice}>
          You are currently viewing the
          {' '}
          <strong>
            {config.environment}
          </strong>
          {' '}
          site
        </div>
      ) : null}
    </>
  );
};

export default App;
