import makeStyles from '../../utils/makeStyles';

export default makeStyles()(() => ({
  submitProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    color: 'currentColor',
  },
  submitProgressCircle: {
    stroke: 'currentColor',
  }
}));
