import useAutoFocus from '@hooks/useAutoFocus';
import { OutlinedInputProps } from '@mui/material/OutlinedInput';
import TextField from '@mui/material/TextField';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import IconFullArrowRight from '@ui/icons/imaterial/arrows/Full Arrow Right.svg';
import React, { useState } from 'react';
import { useRef } from 'react';

import { FancyInputProps } from './FancyInput.props';
import useStyles from './FancyInput.style';

const FancyInput: React.FunctionComponent<FancyInputProps> = (props) => {
  const { 
    onSubmit, 
    align, 
    type, 
    style, 
    children,
    classes: classNames,
    className, 
    id, 
    isSubmitting,
    renderButton,
    hideSubmitButton,
    ...rest 
  } = props;
  const { classes, cx } = useStyles({ align });
  const [value, setValue] = useState<string>(props.defaultValue as string);

  const inputRef = useRef();

  useAutoFocus(inputRef, props.autoFocus);

  return (
    <form
      className={cx(classes.inputWrapper, className, classNames.form)}
      style={style}
      onSubmit={onSubmit}
    >
      <TextField 
        data-test="fancy-input-input"
        margin="none"
        variant="standard"
        {...rest} 
        ref={inputRef}
        InputProps={{
          ...props.InputProps,
          margin: 'none',
          // @ts-ignore
          disableUnderline: true,
          style: {
            marginLeft: 6,
            paddingLeft: 10,
          }
        } as OutlinedInputProps}
        className={cx({
          [classes.input]: true,
          [classes.numberInput]: type === 'number',
          [classNames.textField]: true,
        })}
        defaultValue={undefined}
        value={value}
        id={id}
        onChange={(e) => setValue(e.target.value)}
      />

      {children}

      {
        typeof renderButton === 'function'
          ? renderButton({
            'aria-label': 'submit',
            type: 'submit',
            className: cx(classes.button, classNames.submitButton),
            disabled: !value,
            value,
          }) : hideSubmitButton ?
            null : (
              <SubmitButton
                data-test="fancy-input-submit"
                type="submit"
                className={cx(classes.button, classNames.submitButton)}
                disabled={!value}
                aria-label="submit"
                variant="contained"
                color="secondary"
                isSubmitting={isSubmitting}
              >
                <IconFullArrowRight className={classes.icon} />
              </SubmitButton>
            )
      }
    </form>
  );
};

FancyInput.defaultProps = {
  align: 'left',
  classes: {},
};

export default FancyInput;
