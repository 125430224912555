import { TextFieldProps } from '@mui/material/TextField';
import React from 'react';
import NumberFormat, { NumberFormatProps } from 'react-number-format';

const TextFieldPhoneNumber: React.FC<NumberFormatProps<TextFieldProps>> = (props) => {

  return (
    <NumberFormat
      {...props}
      allowEmptyFormatting
      format="+1 (###) ###-####"
      mask="_"
      style={{ flex: 1,
        color: '#8a8e8e',
        border: 0,
        fontSize: 16,
        lineHeight: 1.5,
      }}
    />
  );
};

export default TextFieldPhoneNumber;
