import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  profileMenu: {
    position: 'fixed',
    top: 2,
    right: -4,
    zIndex: 9999,
    [theme.breakpoints.up('sm')]: {
      top: 5,
      right: 20,    
    }
  },
  main: {
    marginTop: 80,
    minHeight: 'calc(60vh)',
    '& > div': {
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  appEnvironmentNotice: {
    background: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
    position: 'fixed',
    bottom: 0,
    right: 0,
    padding: theme.spacing(1, 1),
    borderTopLeftRadius: theme.shape.borderRadius,
    fontSize: 10,
  }
}));
