import Error from '@components/error/Error';
import React from 'react';

function _404() {
  return (
    <Error
      statusCode={404}
    />
  );
}

export default _404;
