import { auth, database } from '@app/firebase';
import { getIdTokenResult, User as FirebaseUser } from 'firebase/auth';
import { collection, doc, getDoc } from 'firebase/firestore';

const getUserData = async (user: FirebaseUser) => {
  let data: guesthouse.User | undefined = undefined;
  let flags: guesthouse.Flags | undefined = undefined;
  let regions: string | object | undefined = undefined; // TODO: better typing?
  let roles : guesthouse.Roles | undefined = undefined;

  if (user && auth?.currentUser && !auth.currentUser.isAnonymous) {
    const idToken = await getIdTokenResult(auth.currentUser, true);

    flags = idToken.claims.flags && idToken.claims.flags as guesthouse.Flags;
    regions = idToken.claims.regions ? idToken.claims.regions : [];
    roles = idToken.claims.roles && idToken.claims.roles as guesthouse.Roles;

    const userDoc = await getDoc(doc(collection(database, 'users'), user.uid));

    data = userDoc.data() as guesthouse.User;
  }

  return { data, flags, regions, roles };
};

export default getUserData;
