import { auth } from '@app/firebase';
import Sentry from '@integrations/Sentry';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const CONFIRM_EMAIL_PATH = '/auth/confirm-email';
const RESET_PASSWORD_PATH = '/auth/new-password';

export default function useFirebaseEmailHandler() {
  const router = useRouter();
  
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);

    if (isSignInWithEmailLink(auth, window.location.href)) {
      const email = window.localStorage.getItem('emailForSignIn');

      if (!email && router.pathname !== CONFIRM_EMAIL_PATH) {
        router.push({ 
          pathname: CONFIRM_EMAIL_PATH,
          query: router.query, 
        });
      } else {
        signInWithEmailLink(auth, email, window.location.href)
          .then(() => {
            window.localStorage.removeItem('emailForSignIn');

            const continueUrl = router.query.continueUrl?.toString();

            router.push(continueUrl || '/');
          })
          .catch(Sentry.captureException);
      }
    } else if (searchParams.get('mode')) {
      const oobCode = searchParams.get('oobCode');

      switch (searchParams.get('mode')) {
        case 'resetPassword':
          if (!oobCode.length) {
            Sentry.captureException('Store password password failed, URL included no OOB Code', { extra: { continueURL: searchParams.get('continueUrl') } });
          }

          router.push({
            pathname: RESET_PASSWORD_PATH,
            query: { oobCode },
          });
          break;
      }
    }
  }, []);
}
