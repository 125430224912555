import { auth, database, functions } from '@app/firebase';
import TextFieldPhoneNumber from '@components/textfield-phone-number/TextFieldPhoneNumber';
import Sentry from '@integrations/Sentry';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import RegisterSchema, { initialValues } from '@schema/RegisterSchema';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import IconEyeClosed from '@ui/icons/imaterial/base/Eye Crossed.svg';
import IconEyeOpen from '@ui/icons/imaterial/base/Eye Open.svg';
import formatPhone from '@ui/utils/formatPhone';
import userFullName from '@ui/utils/userFullName';
import { validateEmailNotExists } from '@ui/utils/validators';
import connectNewStreamUser from '@utils/connectNewStreamUser';
import { browserLocalPersistence, createUserWithEmailAndPassword, setPersistence, signInWithEmailAndPassword, updateProfile, User as FirebaseUser } from 'firebase/auth';
import { collection, doc, getDoc, setDoc, Timestamp, writeBatch } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import debounce from 'lodash/debounce';
import React, { useState } from 'react';

const validateEmailNotExistsDebounce = debounce(validateEmailNotExists, 500);

const setClaims = httpsCallable<guesthouse.functions.SetClaimsRequest, guesthouse.functions.SetClaimsResponse>(functions, 'http-setClaims');
const deleteAuthUser = httpsCallable<{ uid: string }, void>(functions, 'http-deleteAuthUser');

interface RegisterFormProps {
  onLoginSuccess: (user: FirebaseUser) => void;
  submitText?: string;
  onLoginStart?: () => void;
  onLoginError?: (e?: Error) => void;
}

type CleanupAnonymousUser = () => Promise<void>;

const createCleanAnonymousUser = (anonymousUser : FirebaseUser) : CleanupAnonymousUser => {
  const cleanupAnonymousUser = async () => {
    try {
      // don't await so it can happen async
      deleteAuthUser({ uid: anonymousUser.uid });
    } catch (e) {
      Sentry.captureException(`Failed to delete anonymous user: ${e.message}`);
    }
  };

  return cleanupAnonymousUser;
};

const RegisterForm = ({ onLoginSuccess, onLoginStart, onLoginError, submitText = 'Continue' }: RegisterFormProps) => {
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <Formik
      validationSchema={RegisterSchema}
      initialValues={initialValues}
      onSubmit={async (values: RegisterForm) => {
        setError(false);
        typeof onLoginStart === 'function' && onLoginStart();
        const { password, firstname, lastname } = values;
        let { email } = values;

        if (typeof email === 'string' && email.length) {
          email = email.toLocaleLowerCase();
        }
        
        const batch = writeBatch(database);

        let anonCartData: Partial<guesthouse.Cart> = {};
        let anonRoomsData: guesthouse.UserRoom[];
        let cleanupAnonymousUser : CleanupAnonymousUser;

        if (auth.currentUser && auth.currentUser.isAnonymous) {
          const anonCart = await getDoc(doc(collection(database, 'carts'), auth.currentUser.uid));

          anonCartData = anonCart.data() as guesthouse.Cart;

          cleanupAnonymousUser = createCleanAnonymousUser(auth.currentUser);
        }

        return createUserWithEmailAndPassword(auth, email, password)
          .then(async (userCredential) => {
            const uid = userCredential.user.uid;
            const now = new Date();
            const firestoreNow = Timestamp.fromDate(now);

            const userRoles: guesthouse.Roles = {
              user: true,
              realtor: true
            };

            const userData: guesthouse.User = {
              docID: userCredential.user.uid,
              email: email,
              firstname,
              lastname,
              created: firestoreNow,
              last_login: firestoreNow,
              roles: userRoles,
              flags: {},
              notificationPreferences: {
                messages: {
                  email: true,
                  sms: true,
                },
                timeline_reminders: {
                  email: true,
                  sms: true,
                }
              },
            };

            if (values.phone) {
              try {
                userData.phone = formatPhone(values.phone);
              } catch (e) {
                Sentry.captureException(e);
              }
            }

            try {
              await updateProfile(auth.currentUser, {
                displayName: userFullName(userData),
              });
            } catch (e) {
              Sentry.captureException(e);
            }

            try {
              await connectNewStreamUser(userData);
            } catch (e) {
              Sentry.captureException(e);
            }

            let userDocSetError: Error;
            
            try {
              await setDoc(
                doc(collection(database, 'users'), uid),
                userData,
                { merge: true }
              );
            } catch (e) {
              userDocSetError = e;
              Sentry.captureException(e);
              throw new Error(userDocSetError.message);
            } finally {
              // if for any reason setting the user doc fails, remove from auth table
              if (userDocSetError) {
                await deleteAuthUser({ uid });
              }
            }

            if (anonCartData) {
              try {
                await setDoc(
                  doc(collection(database, 'carts'), uid),
                  { user: uid, products: anonCartData.products },
                  { merge: true }
                );
              } catch (e) {
                Sentry.captureException(new Error(`Failed to merge anonymous user cart data: ${e.message}`));
              }
            }
            
            if (anonRoomsData?.length) {
              for (const userRoom of anonRoomsData) {
                await setDoc(doc(collection(database, `users/${uid}/rooms`)
                  , userRoom.docID)
                , { ...userRoom, products_loaded: false });
              }
            }
            
            if (anonRoomsData?.length) {
              for (const userRoom of anonRoomsData) {
                batch.update(
                  doc(collection(doc(collection(database, 'users'), uid), 'rooms'), userRoom.docID),
                  { products_loaded: true }
                );
              }
            }
            
            try {
              await batch.commit();
            } catch (e) {
              Sentry.captureException(new Error(`Failed to merge anonymous user cart data: ${e.message}`));
            }

            // Claims values will be set on user data in cloud function
            const claimsResponse = await setClaims({ claims: { flags: {}, regions: [], roles: { user: true, realtor: true } }, userId: uid });
            const { status } = claimsResponse.data;

            if (status !== 'success') {
              throw ({ message: 'Backend failure' });
            }

            try {
              window.analytics?.track('sign_up', {
                userId: uid,
                roles: userRoles
              });
            } catch (e) {
              Sentry.captureException(e);
            }

            return userCredential;
          })
          .then(({ user }) => {
            if (typeof onLoginSuccess === 'function') {
              try {
                onLoginSuccess(user);
              } catch (e) {
                Sentry.captureException(e);
              }
            }
          })
          .then(() => setPersistence(auth, browserLocalPersistence))
          .then(() => signInWithEmailAndPassword(auth, email, password))
          .then(() => {
            if (typeof cleanupAnonymousUser === 'function') {
              try {
                return cleanupAnonymousUser();
              } catch (e) {
                Sentry.captureException(new Error(`Failed to cleanup anonymous user: ${e.message}`));
              }
            }
          })
          .catch((e) => {
            Sentry.captureException(new Error(`Failed to register user: ${e.message}`));
            setError(e.message);
            typeof onLoginError === 'function' && onLoginError(e);
          });
      }}
    >
      {({
        errors,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldTouched,
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Box
              display="grid"
              gridTemplateColumns="1fr 1fr"
              gap="10px"
            >
              <Field
                fullWidth
                data-test="firstname"
                name="firstname"
                label="First name"
                type="text"
                component={TextField}
                margin="dense"
                variant="filled"
              />

              <Field
                fullWidth
                data-test="lastname"
                name="lastname"
                label="Last name"
                type="text"
                component={TextField}
                margin="dense"
                variant="filled"
              />
            </Box>

            <Field
              fullWidth
              data-test="email"
              name="email"
              label="Email"
              type="text"
              validate={validateEmailNotExistsDebounce}
              component={TextField}
              margin="dense"
              variant="filled"
            />

            <Field
              fullWidth
              data-test="phone"
              name="phone"
              label="Phone"
              type="text"
              component={TextFieldPhoneNumber}
              customInput={TextField}
              margin="dense"
              variant="filled"
              onBlur={() => {
                setFieldTouched('phone', true);
              }}
              onValueChange={(values) => {
                setFieldValue('phone', values.value);
              }}
            />

            <Field
              fullWidth
              data-test="password"
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              component={TextField}
              margin="dense"
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      position: 'absolute',
                      right: 16,
                    }}
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? <IconEyeClosed /> : <IconEyeOpen />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              sx={{
                '& .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl': {
                  paddingRight: 0
                }
              }}
            />

            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors)?.length)}
                aria-label={submitText}
                data-test="register-continue"
              >
                {submitText}
              </SubmitButton>
            </div>

            {error && (
              <FormHelperText error>
                {error}
              </FormHelperText>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

RegisterForm.defaultProps = {
  onLoginSuccess: () => null,
};

export default RegisterForm;
