import { Theme } from '@mui/material/styles';
import useBreakpoints from '@ui/hooks/useBreakpoints';
import makeStyles from '@ui/utils/makeStyles';
import React, { forwardRef } from 'react';

const makeSpacing = (theme: Theme, spacing: any | any[]) => {
  if (typeof spacing === 'string') {
    return spacing;
  }

  if (Array.isArray(spacing) && spacing.length < 5) {
    return theme.spacing.apply(this, spacing);
  }

  if (Number(spacing)) {
    return theme.spacing(Number(spacing));
  }

  return theme.spacing(0);
};


const useStyles = makeStyles<ContentBlockStyleProps>()((theme, props) => ({
  mainContent: {
    width: '100%',
    maxWidth: props.maxWidth,
    padding: makeSpacing(theme, props.padding),
    margin: makeSpacing(theme, props.margin),
  },
}));

type SpacingValue = number | string | (number | string)[];

interface ContentBlockStyleProps {
  maxWidth?: number | string;
  padding?: SpacingValue;
  margin?: SpacingValue;
  paddingSm?: SpacingValue;
  marginSm?: SpacingValue;
  paddingMd?: SpacingValue;
  marginMd?: SpacingValue;
  paddingLg?: SpacingValue;
  marginLg?: SpacingValue;
  paddingXl?: SpacingValue;
  marginXl?: SpacingValue;
}

interface ContentBlockProps extends ContentBlockStyleProps, React.HTMLProps<HTMLDivElement> {
  className?: string;
  style?: React.CSSProperties;
}

const defaultProps = {
  className: null,
  style: {},
  padding: [0, 3],
  margin: [16, 'auto'],
};

export const ContentBlock: React.FC<ContentBlockProps> = forwardRef<HTMLDivElement, ContentBlockProps>((props, ref) => {
  const { children, className, style } = props;
  let { padding, margin } = props;
  const { theme } = useStyles({});
  const { sm, md, lg, xl } = useBreakpoints();

  if (sm && props.paddingSm) {
    padding = props.paddingSm;
  }

  if (sm && props.marginSm) {
    margin = props.marginSm;
  }

  if (md && props.paddingMd) {
    padding = props.paddingMd;
  }

  if (md && props.marginMd) {
    margin = props.marginMd;
  }

  if (lg && props.paddingLg) {
    padding = props.paddingLg;
  }

  if (lg && props.marginLg) {
    margin = props.marginLg;
  }

  if (xl && props.paddingXl) {
    padding = props.paddingXl;
  }

  if (xl && props.marginXl) {
    margin = props.marginXl;
  }

  const { classes, cx } = useStyles({
    padding,
    margin,
    maxWidth: props.maxWidth || theme.gh_vars.maxWidth
  });

  return (
    <div
      ref={ref}
      className={cx(classes.mainContent, className)}
      style={style}
    >
      {children}
    </div>
  );
});

ContentBlock.defaultProps = defaultProps;
