import React from 'react';

export const defaultNotificationContext: NotificationContext = {
  open: false,
  message: null,
  severity: 'info',
  setContext: () => { }
};

export default React.createContext<NotificationContext>(defaultNotificationContext);
