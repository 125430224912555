import CONFIG from '@config';
import { Analytics,getAnalytics } from 'firebase/analytics';
import { getApp,getApps, initializeApp } from 'firebase/app';
import { connectAuthEmulator,getAuth } from 'firebase/auth';
import { connectFirestoreEmulator, initializeFirestore, LogLevel, setLogLevel } from 'firebase/firestore';
import { connectFunctionsEmulator,getFunctions } from 'firebase/functions';
import { connectStorageEmulator,getStorage } from 'firebase/storage';

const app = getApps().length === 0
  ? initializeApp(CONFIG.firebase.store)
  : getApp();

if (CONFIG.debug.firestore && typeof CONFIG.debug.firestore === 'string' && setLogLevel) {
  setLogLevel(CONFIG.debug.firestore as LogLevel);
}

let analytics: Analytics;

if (typeof window !== 'undefined' && CONFIG.firebase.store.measurementId) {
  analytics = getAnalytics();
}

const functions = getFunctions(app);

if (CONFIG.environment === 'local' && CONFIG.functions) {
  console.log('Using functions configuration', CONFIG.functions);
  connectFunctionsEmulator(functions, CONFIG.functions.host, CONFIG.functions.port);
}

const databaseSettings = CONFIG.firestore?.settings ?? {};
const database = initializeFirestore(app, databaseSettings);

if (CONFIG.environment === 'local' && CONFIG.firestore) {
  console.log('Using firestore configuration', CONFIG.firestore);

  try {
    connectFirestoreEmulator(database, CONFIG.firestore.host, CONFIG.firestore.port);
  } catch (e) {
    // console.error(e);
  }
}

const auth = getAuth(app);

if (CONFIG.environment === 'local' && CONFIG.auth) {
  console.log('Using auth configuration', CONFIG.auth);
  connectAuthEmulator(auth, CONFIG.auth.url);
}

const storage = getStorage(app);

if (CONFIG.environment === 'local' && CONFIG.storage) {
  console.log('Using storage configuration', CONFIG.auth);
  connectStorageEmulator(storage, CONFIG.storage.host, CONFIG.storage.port);
}

export { auth };
export { storage };
export { functions };
export { database };
export { analytics };

export default app;
