export type SimplePrice = {
  /**
   * The individual product price after discounts
   */
  salePrice?: number;
  /**
   * The original price before discounts
   */
  originalPrice?: number;
  /**
   * The percent difference between sale price and original price
   */
  percentDiscount?: number;
}

export const offerPrice = (offer: Partial<guesthouse.ProductOffer>) : SimplePrice => {
  if (!offer) {
    return {
      salePrice: 0,
      originalPrice: 0,
      percentDiscount: 0,
    };
  }

  const originalPrice = offer.price;
  let salePrice = offer.price;
  let percentDiscount = 0;

  if (offer.sale && offer.salePrice) {
    salePrice = offer.salePrice;
  }

  if (salePrice < originalPrice) {
    percentDiscount = Math.floor(((originalPrice - salePrice) / originalPrice) * 100);
  }

  return { salePrice, originalPrice, percentDiscount };
};

export const orderProductTotal = (orderProduct: Pick<guesthouse.OrderProduct, 'offer' | 'quantity' | 'discount'>) : guesthouse.SimpleOrderProductTotal => {
  const { salePrice } = offerPrice(orderProduct.offer);

  let orderShipping = 0;
  let individualShipping = 0;
  let individualProductWithShipping = salePrice;

  
  if (orderProduct.offer && !orderProduct.offer.pickupOnly) {
    individualProductWithShipping += orderProduct.offer.shippingPricePerProduct || 0;
    individualShipping = orderProduct.offer.shippingPricePerProduct || 0;
    orderShipping = orderProduct.offer.shippingPricePerOrder || 0;
  }

  const total = (individualProductWithShipping * orderProduct.quantity) + orderShipping;
  const productTotal = salePrice * orderProduct.quantity;
  const shippingTotal = (individualShipping * orderProduct.quantity) + orderShipping;

  return {
    total,
    productTotal,
    shippingTotal,
    discountTotal: orderProduct.discount || 0,
  };
};
