
import EmailSignup from '@components/email-signup/EmailSignup';
import FooterContext from '@context/FooterContext';
import MenuContext from '@context/MenuContext';
import SvgGuesthouseLogoIcon from '@images/guesthouse-logo-icon';
import IconFacebook from '@mui/icons-material/Facebook';
import IconInstagram from '@mui/icons-material/Instagram';
import IconLinkedin from '@mui/icons-material/LinkedIn';
import IconPinterest from '@mui/icons-material/Pinterest';
import Grid from '@mui/material/Grid';
import MuiLink from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Link from 'next/link';
import React, { useContext } from 'react';

import useStyles from './Footer.style';

const copyrightYear = new Date().getFullYear();

const Footer: React.FC = () => {
  const { classes, cx, theme } = useStyles();
  const menuContext = useContext<MenuContext>(MenuContext);
  const footerContext = useContext<FooterContext>(FooterContext);

  const NAV_1 = [
    {
      href: '/home-staging',
      text: 'Stage',
    },
    {
      href: '/sell',
      text: 'Sell',
    },
    {
      href: '/shop/homes',
      text: 'Homes',
    },
  ];


  const NAV_2 = [
    {
      href: '/about',
      text: 'About Guest House',
    },
    {
      href: '/design',
      text: 'Design Consults'
    },
    {
      href: '/home-buyer',
      text: 'Homebuyer Program'
    },
    {
      href: '/sell',
      text: 'Brands'
    },
    {
      href: '/press',
      text: 'Press',
    },
    {
      href: '/careers',
      text: 'Careers',
    },
    {
      href: '/home-staging/denver',
      text: 'Denver & Boulder Staging',
    },
    {
      href: '/home-staging/san-diego',
      text: 'San Diego Staging',
    },
    {
      href: '/home-staging/orange-county',
      text: 'Orange County Staging'
    },
    {
      href: '/affirm',
      text: 'Financing'
    },
    {
      href: '/support',
      text: 'Support'
    }
  ];

  const NAV_3 = [
    {
      href: '/terms-and-conditions',
      text: 'Terms & Conditions',
    },
    {
      href: '/privacy',
      text: 'Privacy',
    },
    {
      href: '/cookies',
      text: 'Cookies',
    },
    {
      href: '/sitemap.html',
      text: 'Sitemap',
    },
  ];

  if (!menuContext.showFooter) {
    return null;
  }

  return (
    <footer className={classes.footerWrapper}>
      <div className={classes.footerContent}>
        <Grid container>
          <Grid
            item
            xs={12}
            md={2}
            style={{ marginBottom: theme.spacing(4) }}
          >
            <Link
              href="/"
              title="Guest House"
            >

              <SvgGuesthouseLogoIcon className={classes.footerIcon} />

            </Link>
          </Grid>

          <Grid
            item
            xs={12}
            md={2}
            style={{ marginBottom: theme.spacing(4) }}
          >
            <ul className={classes.navigationList}>
              {NAV_1.map((item, i) => {
                return (
                  <li
                    key={i}
                    className={classes.navigationPrimaryItem}
                  >
                    <Link href={item.href}>

                      {item.text}

                    </Link>
                  </li>
                );
              })}
            </ul>
          </Grid>

          <Grid
            item
            xs={12}
            md={2}
            style={{ marginBottom: theme.spacing(4) }}
          >
            <ul className={classes.navigationList}>
              {NAV_2.map((item, i) => {
                return (
                  <li
                    key={i}
                    className={classes.navigationSecondaryItem}
                  >
                    <Link href={item.href}>

                      {item.text}

                    </Link>
                  </li>
                );
              })}
            </ul>
          </Grid>

          <Grid
            item
            xs={12}
            md={3}
          >

          </Grid>

          <Grid
            item
            xs={12}
            md={3}
            style={{ paddingTop: theme.spacing(2) }}
          >
            <Typography
              gutterBottom
              component="h2"
              variant="h3Alt"
            >
              Join Our Newsletter
            </Typography>

            <Typography style={{ marginBottom: theme.spacing(4) }}>
              Sign up for new products, home launches, and special events. Unsubscribe anytime.
            </Typography>

            <EmailSignup
              campaignList="shoppers"
            />

            <Grid
              container
              style={{ marginTop: theme.spacing(2) }}
            >
              <Grid
                item
                className={classes.footerSocial}
              >
                <a
                  href="https://www.pinterest.com/guesthouseshop/_created/"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Guest House Pinterest"
                >
                  <IconPinterest className={classes.footerSocialIcon} />
                </a>
              </Grid>

              <Grid
                item
                className={classes.footerSocial}
              >
                <a
                  href="https://www.instagram.com/guesthouseshop/"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Guest House Instagram"
                >
                  <IconInstagram className={classes.footerSocialIcon} />
                </a>
              </Grid>

              <Grid
                item
                className={classes.footerSocial}
              >
                <a
                  href="https://www.linkedin.com/company/guest-house-shop/about/"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Guest House Linkedin"
                >
                  <IconLinkedin className={classes.footerSocialIcon} />
                </a>
              </Grid>

              <Grid
                item
                className={classes.footerSocial}
              >
                <a
                  href="https://www.facebook.com/guesthouseshop"
                  rel="noopener noreferrer"
                  target="_blank"
                  aria-label="Guest House Facebook"
                >
                  <IconFacebook className={classes.footerSocialIcon} />
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          className={classes.footerLegal}
        >
          <Grid
            item
            xs={12}
            md={2}
          >
            <span style={{ color: '#8a8e8e', lineHeight: '28px' }}>
              &copy;
              {' '}
              {copyrightYear}
              {' '}
              Guest House
            </span>
          </Grid>

          <Grid
            item
            xs={12}
            md={4}
          >
            <Typography
              component="span"
              style={{
                marginRight: theme.spacing(3),
                fontWeight: 'bold',
                fontFamily: theme.gh_vars.circular,
              }}
            >
              Give us a call
            </Typography>
            <MuiLink href={footerContext.phoneNumberHref}>
              {footerContext.phoneNumberText}
            </MuiLink>
          </Grid>

          <Grid
            item
            xs={12}
            md={6}
          >
            <div className={classes.footerLegalListWrapper}>
              <ul className={cx(classes.navigationList, classes.footerLegalList)}>
                {NAV_3.map((item, i) => {
                  return (
                    <li
                      key={i}
                      className={classes.footerLegalItem}
                    >
                      <Link href={item.href}>

                        {item.text}

                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Grid>
        </Grid>
      </div>
    </footer>
  );
};

export default Footer;
