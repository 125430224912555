import { database } from '@app/firebase';
import Sentry from '@integrations/Sentry';
import { highestOffer } from '@ui/components/product-offer/ProductOffer';
import { User as FirebaseUser } from 'firebase/auth';
import { collection, doc, getDoc, setDoc, writeBatch } from 'firebase/firestore';

import getFirebaseUser from './getFirebaseUser';
import newId from './newId';
import { productToSegmentEventData } from './segmentAnalytics';


export async function addToCart(cartContext: CartContext, product: guesthouse.Product | guesthouse.ProductLite, offer: guesthouse.ProductOffer, quantity: number, showCart?: boolean, userRoomId?: string): Promise<void> {
  const key = userRoomId ? `${offer.docID}:room_${userRoomId}` : offer.docID;
  const cartProductQuantity = cartContext.products && cartContext.products[key] ? cartContext.products[key].quantity : 0;
  let updatedProductQuantity = cartProductQuantity ? quantity + cartProductQuantity : quantity;

  if (updatedProductQuantity < 0) {
    updatedProductQuantity = 0;
  }

  const user = await getFirebaseUser();

  if (user) {
    const cart: guesthouse.Cart = {
      user: user.uid,
      products: {
        [key]: {
          quantity: updatedProductQuantity,
          offerID: offer.docID,
          productID: product.docID,
        }
      }
    };

    if (cartContext?.coupon) {
      cart.coupon = cartContext.coupon;
    }

    await setDoc(
      doc(collection(database, 'carts'), user.uid),
      cart,
      { merge: true }
    );

    if (showCart) {
      cartContext.setContext(prev => ({ ...prev, cartDrawerOpen: true }));
    }

    try {
      window.analytics?.track('Product Added', productToSegmentEventData(product));
    } catch (e) {
      Sentry.captureException(e);
    }
  } else {
    Sentry.captureException(new Error('Tried to add to cart, but could not get Firebase user.'));
  }
}

type CartProd = {
  quantity: number;
  offerID: string;
  productID: string;
}

export const addToCartBatch = async (
  user: FirebaseUser | void, 
  cartContext: CartContext, 
  cartProducts: guesthouse.CartProduct[], 
  showCart?: boolean, 
  userRoom?: guesthouse.UserRoom,
) => {
  if (user) {
    const products: Record<string, CartProd> = {};

    for (const cartProduct of cartProducts) {
      const { product, quantity } = cartProduct;
      const offer = cartProduct.offer || highestOffer(product.offers);
      const key = userRoom?.docID ? `${offer?.docID}:room_${userRoom?.docID}` : offer?.docID;
      const cartProductQuantity = cartContext.products && cartContext.products[key] ? cartContext.products[key].quantity : 0;
      let updatedProductQuantity = cartProductQuantity ? quantity + cartProductQuantity : quantity;

      if (!cartProduct.room) {
        cartProduct.room = userRoom;
      }

      if (updatedProductQuantity < 0) {
        updatedProductQuantity = 0;
      }

      if (products[key]) {
        products[key].quantity += quantity;
      } else {
        products[key] = {
          quantity: updatedProductQuantity,
          offerID: offer?.docID,
          productID: product?.docID,
        };
      }

      try {
        window.analytics?.track('Product Added', productToSegmentEventData(product));
      } catch (e) {
        Sentry.captureException(e);
      }
    }
    const cart: guesthouse.Cart = {
      user: user.uid,
      products
    };

    try {
      await setDoc(
        doc(collection(database, 'carts'), user.uid),
        cart,
        { merge: true }
      );
    } catch (e) {
      Sentry.captureException(e);
    }

    if (showCart) {
      cartContext.setContext(prev => ({ ...prev, cartDrawerOpen: true }));
    }
  } else {
    Sentry.captureException(new Error('Tried to add to cart, but could not get Firebase user.'));
  }
};

export const addRoomFirebaseCart = async (
  user: FirebaseUser | void, 
  cartContext: CartContext, 
  cartProducts: guesthouse.CartProduct[], 
  room: guesthouse.Room | Partial<guesthouse.UserRoom>,
  showCart?: boolean,
) => {
  const batch = writeBatch(database);
  const newRoom = {
    ...room,
    original_room: room.docID,
    docID: newId(),
  } as guesthouse.UserRoom;

  if (user && user.uid) {
    const firestoreUser = await getDoc(doc(collection(database, 'users'), user.uid))
      .then(doc => {
        if (doc.exists()) {
          return doc.data() as guesthouse.User;
        }
      });

    // anon users
    if (!firestoreUser) {
      try {
        await setDoc(doc(collection(database, 'users'), user.uid), {
          created: new Date(),
          updated: new Date(),
          docID: user.uid,
        });
      } catch (e) {
        Sentry.captureException(e);
      }
    }
    try {
      const userDoc = doc(collection(database, 'users'), user.uid);
      const newRoomDoc = doc(collection(userDoc, 'rooms'), newRoom.docID);

      await setDoc(newRoomDoc, newRoom);

      await addToCartBatch(user, cartContext, cartProducts, false, newRoom);

      for (const cartProduct of cartProducts) {
        cartProduct.room = newRoom;

        batch.set(
          doc(
            collection(doc(collection(doc(collection(database, 'users'), user.uid), 'rooms'), newRoom.docID), 'room_products'),
            cartProduct.product.docID
          ),
          cartProduct,
          { merge: true }
        );

        try {
          window.analytics?.track('Product Added', productToSegmentEventData(cartProduct.product));
        } catch (e) {
          Sentry.captureException(e);
        }
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  } else {
    return Sentry.captureException(new Error('Tried to add to cart, but could not get Firebase user.'));
  }

  try {
    await batch.commit();
  } catch (e) {
    return Sentry.captureException(e);
  }

  if (showCart) {
    cartContext.setContext(prev => ({ ...prev, cartDrawerOpen: true }));
  }
};
