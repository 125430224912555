import { auth, database, functions } from '@app/firebase';
import Sentry from '@integrations/Sentry';
import FormHelperText from '@mui/material/FormHelperText';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import LoginSchema, { initialValues } from '@schema/LoginSchema';
import SubmitButton from '@ui/components/buttons/SubmitButton';
import IconEyeClosed from '@ui/icons/imaterial/base/Eye Crossed.svg';
import IconEyeOpen from '@ui/icons/imaterial/base/Eye Open.svg';
import { browserLocalPersistence,setPersistence, signInWithEmailAndPassword, User as FirebaseUser } from 'firebase/auth';
import { collection, doc, getDoc, setDoc, updateDoc, writeBatch } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Field, Formik } from 'formik';
import { TextField } from 'formik-mui';
import React, { useState } from 'react';

interface LoginFormProps {
  onLoginSuccess: (user: FirebaseUser) => void;
  submitText?: string;
  onLoginStart?: () => void;
  onLoginError?: (e?: Error) => void;
}

type CleanupAnonymousUser = () => Promise<void>;

const deleteAuthUser = httpsCallable<{uid: string}, void>(functions, 'http-deleteAuthUser');

const createCleanAnonymousUser = (anonymousUser: FirebaseUser): CleanupAnonymousUser => {
  const cleanupAnonymousUser = async () => {
    try {
      // don't await so it can happen async
      deleteAuthUser({ uid: anonymousUser.uid });
    } catch (e) {
      Sentry.captureException(`Failed to delete anonymous user: ${e.message}`);
    }
  };

  return cleanupAnonymousUser;
};

const LoginForm = ({ onLoginSuccess, onLoginStart, onLoginError, submitText = 'Continue' }: LoginFormProps) => {
  const [error, setError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => setShowPassword(!showPassword);

  return (
    <Formik
      validationSchema={LoginSchema}
      initialValues={initialValues}
      onSubmit={async (values: LoginForm) => {
        setError(false);
        typeof onLoginStart === 'function' && onLoginStart();
        const { email, password } = values;
        const batch = writeBatch(database);

        let anonCartData: Partial<guesthouse.Cart> = {};
        let anonRoomsData: guesthouse.UserRoom[];
        let cleanupAnonymousUser: CleanupAnonymousUser;

        if (auth.currentUser && auth.currentUser.isAnonymous) {
          const anonCart = await getDoc(doc(collection(database, 'carts'), auth.currentUser.uid));
          
          anonCartData = anonCart.data() as guesthouse.Cart;
          
          cleanupAnonymousUser = createCleanAnonymousUser(auth.currentUser);
        }

        try {
          await setPersistence(auth, browserLocalPersistence);
          const { user } = await signInWithEmailAndPassword(auth, email, password);

          if (anonCartData) {
            batch.set(
              doc(collection(database, 'carts'), user.uid), 
              { products: anonCartData.products }, 
              { merge: true }
            );
          }

          if (anonRoomsData?.length) {
            for (const userRoom of anonRoomsData) {
              await setDoc(doc(collection(database, `users/${user.uid}/rooms`)
                , userRoom.docID)
              , { ...userRoom, products_loaded: false });
            }
          }
          
          if (anonRoomsData?.length) {
            for (const userRoom of anonRoomsData) {
              batch.update(
                doc(collection(doc(collection(database, 'users'), user.uid), 'rooms'), userRoom.docID),
                { products_loaded: true }
              );
            }
          }

          try {
            await batch.commit();
          } catch (e) {
            Sentry.captureException(new Error(`Failed to merge anonymous user cart data: ${e.message}`));
          }

          if (typeof cleanupAnonymousUser === 'function') {
            try {
              return cleanupAnonymousUser();
            } catch (e) {
              Sentry.captureException(new Error(`Failed to cleanup anonymous user: ${e.message}`));
            }
          }

          try {
            await updateDoc(
              doc(collection(database, 'users'), user.uid),
              { last_login: new Date() }
            );
          } catch (e) {
            Sentry.captureException(e);
          }

          onLoginSuccess(user);
        } catch (e) {
          Sentry.captureException(new Error(`Failed to login user: ${e.message}`));
          setError(e.message);
          typeof onLoginError === 'function' && onLoginError(e);
        }
      }}
    >
      {({
        errors,
        handleSubmit,
        isSubmitting
      }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field
              fullWidth
              data-test="login-email"
              name="email"
              label="Email"
              type="email"
              component={TextField}
              margin="dense"
              variant="filled"
            />

            <Field
              fullWidth
              data-test="login-password"
              name="password"
              label="Password"
              type={showPassword ? 'text' : 'password'}
              component={TextField}
              margin="dense"
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    style={{
                      position: 'absolute',
                      right: 8,
                    }}
                  >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={toggleShowPassword}
                    >
                      {showPassword ? <IconEyeClosed /> : <IconEyeOpen />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
              sx={{
                '& .MuiFilledInput-root.MuiFilledInput-underline.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl': {
                  paddingRight: 0
                }
              }}
            />

            {error && (
              <FormHelperText
                error
                data-test="login-error"
              >
                {error}
              </FormHelperText>
            )}

            <div style={{ paddingTop: 20 }}>
              <SubmitButton
                fullWidth
                data-test="login-submit"
                isSubmitting={isSubmitting}
                disabled={Boolean(Object.keys(errors)?.length)}
                aria-label={submitText}
              >
                {submitText}
              </SubmitButton>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

LoginForm.defaultProps = {
  onLoginSuccess: () => null,
};

export default LoginForm;
