import { parseAddress } from 'parse-address';

const shortAddress = (address: string) => {
  try {
    const parsed = parseAddress(address.replace(', USA', ''));

    return [
      parsed.number,
      parsed.prefix,
      parsed.street,
      parsed.type,
      parsed.sec_unit_type,
      parsed.sec_unit_num,
    ].filter(i => i).join(' ');
  } catch (e) {
    return address;
  }
};

export const cityState = (address: string) => {
  try {
    const parsed = parseAddress(address.replace(', USA', ''));

    return [
      parsed.city,
      parsed.state
    ].filter(i => i).join(', ');
  } catch (e) {
    return address;
  }
};

export const unparseAddress = (address: guesthouse.Address) : string => {
  let ret = '';

  ret += address.address_1;

  if (address.address_2) {
    ret += ` ${ address.address_2}`;
  }

  ret += `, ${address.city}, ${address.state}, ${address.postal_code}`;

  return ret;
};


export default shortAddress;
