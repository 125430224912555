import makeStyles from '../../utils/makeStyles';

export default makeStyles()((theme) => ({
  quantitySelect: {
    fontSize: 16,
  },
  count: {
    margin: theme.spacing(0),
    lineHeight: 1,
    position: 'relative',
    bottom: 1,
    width: 20,
    textAlign: 'center',
    paddingTop: 2,
    fontFamily: theme.gh_vars.mabryMono
  },
  iconButton: {
    '& svg': {
      width: '1em',
      height: '1em',
    }
  },
  qtyText: {
    fontSize: 11,
    fontFamily: theme.gh_vars.mabryMono,
    color: '#666666',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('md')]: {
      fontSize: 12,
    },
  }
}));
