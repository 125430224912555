import '../public/fonts/stylesheet.css';
import '@sass/global.scss';
import 'intersection-observer';
import '@sass/hamburgers.scss';
import '@sass/nprogress.scss';
import '@sass/squarespace.scss';
import '@sass/swiper.scss';

import App from '@app/App';
import { auth, database, functions } from '@app/firebase';
import AppBackgroundColor from '@components/app-background-color/AppBackgroundColor';
import Notification from '@components/notification/Notification';
import CONFIG from '@config';
import CartContext, { defaultCartContext } from '@context/CartContext';
import FooterContext, { defaultFooterContext } from '@context/FooterContext';
import MenuContext, { defaultMenuContext } from '@context/MenuContext';
import NotificationContext, { defaultNotificationContext } from '@context/NotificationContext';
import UserContext, { defaultUserContext } from '@context/UserContext';
import { CacheProvider } from '@emotion/react';
import useFirebaseEmailHandler from '@hooks/useFirebaseEmailHandler';
import useHubSpotConversationsWidget from '@hooks/useHubSpotConversationsWidget';
import useMaintenanceMode, { Maintenance } from '@hooks/useMaintenanceMode';
import Sentry from '@integrations/Sentry';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { AnalyticsBrowser } from '@segment/analytics-next';
import theme from '@ui/theme';
import brandLogger from '@ui/utils/brandLogger';
import makeStoreUrl from '@ui/utils/makeStoreUrl';
import userFullName from '@ui/utils/userFullName';
import { createMuiCache } from '@utils/createMuiCache';
import getFirebaseUser from '@utils/getFirebaseUser';
import getUserData from '@utils/getUserData';
import { onAuthStateChanged,signInAnonymously,User as FirebaseUser } from 'firebase/auth';
import { collection, doc,onSnapshot,QuerySnapshot, setDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import cookie from 'js-cookie';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Router, { useRouter } from 'next/router';
import { DefaultSeo } from 'next-seo';
import NProgress from 'nprogress';
import React, { useEffect, useState } from 'react';
import LazyLoad from 'vanilla-lazyload';

import SEO, { title } from '../config/next-seo.config';

if (process.browser) {
  brandLogger(process.env.NEXT_PUBLIC_GITHUB_REF, process.env.NEXT_PUBLIC_GITHUB_SHA);
}
declare global {
  interface Window {
    GUESTHOUSE: guesthouse.Global;
    affirm?: {
      checkout: (options?: Affirm.CheckoutOptions) => void | {
        open: (options?: Affirm.CheckoutOptions) => void;
      }
    };
    analytics: AnalyticsBrowser;
    google_optimize?: {
      get(e: string): string
    };
    HubSpotConversations: HubSpot.ConversationsWidget;
    hsConversationSettings?: HubSpot.ConversationsSettings;
  }
}


NProgress.configure({ showSpinner: false });
Router.events.on('routeChangeStart', () => NProgress.start());
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());
Router.events.on('routeChangeComplete', () => lazyload?.update());

let lazyload;


if (process.browser) {
  window.GUESTHOUSE = window.GUESTHOUSE || {};

  lazyload = new LazyLoad({
    elements_selector: '[data-bg], [data-src], [data-bg-multi]'
  });
}

function makeSaveds<T extends guesthouse.Identifiable>(snapshot: QuerySnapshot<T>): Saveds<T> {
  const ret: Saveds<T> = {
    list: [],
    ids: [],
  };

  if (snapshot.docs && snapshot.docs.length) {
    return snapshot.docs.reduce((acc, cur) => {
      const data = cur.data() as T;

      acc.list.push(data);
      acc.ids.push(data.docID);
      return acc;
    }, ret);
  }
}

type GenerateHubSpotIDTokenRequest = {
  email: string
  firstname: string
  lastname: string
};

const locationInfo = httpsCallable(functions, 'http-locationInfo');
const generateHubSpotIDToken = httpsCallable<GenerateHubSpotIDTokenRequest, string | null>(functions, 'http-generateHubSpotIDToken');

export default function GuestHouseApp(props: AppProps) {
  const {
    Component,
    pageProps
  } = props;

  const [userContext, setUserContext] = useState<UserContext>(defaultUserContext);
  const [cartContext, setCartContext] = useState<CartContext>(defaultCartContext);
  const [footerContext, setFooterContext] = useState<FooterContext>(defaultFooterContext);
  const [menuContext, setMenuContext] = useState<MenuContext>(defaultMenuContext);
  const [notificationContext, setNotificationContext] = useState<NotificationContext>(defaultNotificationContext);

  const [savedProducts, setSavedProducts] = useState<Saveds<guesthouse.Product>>();
  const [savedHomes, setSavedHomes] = useState<Saveds<guesthouse.Home>>();
  const [savedRooms, setSavedRooms] = useState<Saveds<guesthouse.Room>>();
  const [savedUsers, setSavedUsers] = useState<Saveds<guesthouse.User>>();
  const [queryChanged, setQueryChanged] = useState(false);

  const router = useRouter();

  useHubSpotConversationsWidget();
  useFirebaseEmailHandler();

  useEffect(() => {
    window.analytics?.addSourceMiddleware(({ payload, next }) => {
      const dnt = cookie.get(CONFIG.segment.dntCookie);

      if (dnt !== '1') {
        next(payload);
      }
    });
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      window.analytics?.page();
      lazyload?.update();
      
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router]);

  useEffect(() => {
    const handleRouteChange = () => {
      setCartContext(prev => ({ ...prev, cartDrawerOpen: false }));
    };

    router.events.on('routeChangeStart', handleRouteChange);
    return () => router.events.off('routeChangeStart', handleRouteChange);
  });

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      const baseRoute = url.split('?')[0];

      if (url.includes('?')) {
        setQueryChanged(true);
      }

      if (queryChanged !== true && baseRoute === url) {
        window.scrollTo(0, 0);
      }
    };

    router.events.on('routeChangeComplete', handleRouteChange);
    return () => router.events.off('routeChangeComplete', handleRouteChange);
  }, [router, queryChanged]);

  useEffect(() => {
    setUserContext(userContext => ({ ...userContext, savedHomes, savedRooms, savedProducts, savedUsers }));
  }, [savedProducts, savedHomes, savedRooms, savedUsers]);

  useEffect(() => {
    let subscriptions = [];

    const handleAuthStateChange = async (user: FirebaseUser) => {
      if (user) {
        window.GUESTHOUSE.uid = user.uid;

        subscriptions.push(
          onSnapshot(doc(collection(database, 'users'), user.uid),
            async snapshot => {
              if (snapshot) {
                const data = snapshot.data() as guesthouse.User;

                let traits = {};

                if (data) {
                  traits = {
                    name: userFullName(data),
                    email: data?.email,
                    roles: data?.roles,
                    flags: data?.flags
                  };
                  setUserContext(state => ({ ...state, data }));
                  window.analytics?.identify(user.uid, traits);
                }

              }
            })
        );

        subscriptions.push(
          onSnapshot(
            doc(collection(database, 'carts'), user.uid),
            async snapshot => {
              if (snapshot && snapshot.data()) {
                const data = snapshot.data() as guesthouse.Cart;

                setCartContext(state => ({ ...state, ...data }));
              }
            })
        );

        subscriptions.push(
          onSnapshot(
            collection(doc(collection(database, 'users'), user.uid), 'savedProducts'),
            snap => {
              const saveds = makeSaveds(snap as QuerySnapshot<guesthouse.Product>);

              setSavedProducts(saveds);
            })
        );

        subscriptions.push(
          onSnapshot(
            collection(doc(collection(database, 'users'), user.uid), 'savedHomes'),
            snap => {
              const saveds = makeSaveds(snap as QuerySnapshot<guesthouse.Home>);

              setSavedHomes(saveds);
            })
        );

        subscriptions.push(
          onSnapshot(
            collection(doc(collection(database, 'users'), user.uid), 'savedRooms'),
            snap => {
              const saveds = makeSaveds(snap as QuerySnapshot<guesthouse.Room>);

              setSavedRooms(saveds);
            })
        );

        subscriptions.push(
          onSnapshot(
            collection(doc(collection(database, 'users'), user.uid), 'savedUsers')
            ,snap => {
              const saveds = makeSaveds(snap as QuerySnapshot<guesthouse.User>);

              setSavedUsers(saveds);
            })
        );

        const { data, flags, roles } = await getUserData(user);

        setUserContext(state => ({ ...state, data, flags, roles, user }));

        window.analytics?.track('login', {
          userId: user.uid,
          roles: roles
        });

        const sentryScope = Sentry.getCurrentScope();

        sentryScope.setUser({
          email: user.email,
          id: user.uid,
        });

      } else {
        window.GUESTHOUSE.uid = undefined;
        window.analytics?.reset();

        subscriptions.forEach(unsub => unsub());
        subscriptions = [];

        // note: updating the user has to happen after unsubscribing from all previous
        //       subscriptions
        if (cartContext.products)  {
          // if there's no user but ther are still products in the cart,
          // we want to sign in anonymously to keep track of the cart
          const { user } = await signInAnonymously(auth);

          await setDoc(
            doc(collection(database, 'carts'), user.uid),
            { user: user.uid, products: cartContext.products },
            { merge: true }
          );
        } else {
          setUserContext(state => ({ ...state, user }));
          Sentry.configureScope(scope => scope.setUser(null));
        }

      }
    };

    return onAuthStateChanged(auth, handleAuthStateChange);
  }, []);

  useEffect(() => {
    const setHSToken = async (email, firstname, lastname) => {
      const tokens: Record<string, string> = {};

      try {
        const { data } = await generateHubSpotIDToken({
          email: email,
          firstname: firstname,
          lastname: lastname,
        });

        if (data?.length) {
          tokens.hsToken = data;
        }
      } catch (e) {
        Sentry.captureException(e);
      }

      window.hsConversationSettings = {
        identificationEmail: email,
        identificationToken: tokens.hsToken,
      };

      try {
        const widgetStatus = window?.HubSpotConversations?.widget?.status();

        if (widgetStatus?.loaded) {
          window?.HubSpotConversations?.widget?.refresh();
        } else {
          window?.HubSpotConversations?.widget?.load();
        }
      } catch (e) {
        // do nothing, not all pages have this widget enabled in HubSpot configuration.
      }

      setUserContext(state => ({ ...state, tokens }));
    };

    if (userContext?.user?.email) {
      setHSToken(userContext?.user?.email, userContext?.data?.firstname, userContext?.data?.lastname);
    }
  }, [userContext?.user?.email, userContext?.data?.firstname, userContext?.data?.lastname]);

  useEffect(() => {
    locationInfo()
      .then((response) => {
        const geo = response?.data as guesthouse.Geo;

        setUserContext(state => ({ ...state, geo }));
      })
      .catch(Sentry.captureException);
  }, []);

  const closeMenu = () => setMenuContext(prev => ({ ...prev, open: false }));

  useEffect(() => {
    Router.events.on('routeChangeStart', closeMenu);

    return () => Router.events.off('routeChangeStart', closeMenu);
  });

  const maintenanceMode = useMaintenanceMode();

  if (maintenanceMode) {
    return (
      <CacheProvider value={createMuiCache()}>
        <ThemeProvider theme={theme}>
          <CssBaseline />

          <Maintenance />
        </ThemeProvider>
      </CacheProvider>
    );
  }

  return (
    <CacheProvider value={createMuiCache()}>
      <Head>
        <title>
          {title}
        </title>

        <meta
          key="viewport"
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, minimal-ui"
        />

        <link
          key="canonical"
          rel="canonical"
          href={makeStoreUrl(router.asPath)}
        />

        <link
          rel="stylesheet"
          href="/fonts/stylesheet.css"
        />

        <link
          rel="preload"
          href="/fonts/mabry-mono/mabry-mono-regular-pro.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/mabry-mono/mabry-mono-italic-pro.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/mabry-mono/mabry-mono-medium-pro.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/mabry-mono/mabry-mono-medium-italic-pro.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/mabry-mono/mabry-mono-bold-pro.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/mabry-mono/mabry-mono-bold-italic-pro.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/mabry-mono/mabry-mono-regular-pro.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/mabry-mono/mabry-mono-italic-pro.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/mabry-mono/mabry-mono-medium-pro.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/mabry-mono/mabry-mono-medium-italic-pro.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/mabry-mono/mabry-mono-bold-pro.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/mabry-mono/mabry-mono-bold-italic-pro.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-Thin.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-ThinItalic.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-Light.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-LightItalic.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-Regular.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-Italic.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-Book.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-BookItalic.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-Medium.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-MediumItalic.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-Bold.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-BoldItalic.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-Black.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-BlackItalic.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-ExtraBlack.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-ExtraBlackItalic.woff2"
          as="font"
          type="font/woff2"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-Thin.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-ThinItalic.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-Light.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-LightItalic.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-Regular.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-Italic.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-Book.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-BookItalic.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-Medium.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-MediumItalic.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-Bold.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-BoldItalic.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-Black.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-BlackItalic.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-ExtraBlack.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />

        <link
          rel="preload"
          href="/fonts/circular/CircularXXWeb-ExtraBlackItalic.woff"
          as="font"
          type="font/woff"
          crossOrigin="anonymous"
        />
      </Head>

      <DefaultSeo {...SEO} />

      <MenuContext.Provider value={Object.assign(menuContext, { setContext: setMenuContext })}>
        <UserContext.Provider value={Object.assign(userContext, { setContext: setUserContext, getFirebaseUser })}>
          <NotificationContext.Provider value={Object.assign(notificationContext, { setContext: setNotificationContext })}>
            <CartContext.Provider value={Object.assign(cartContext, { setContext: setCartContext })}>
              <FooterContext.Provider value={{ ...footerContext, setContext: setFooterContext }}>
                <ThemeProvider theme={theme}>
                  <CssBaseline />
                  <AppBackgroundColor />

                  <App>
                    <Component {...pageProps} />
                  </App>

                  <Notification />

                </ThemeProvider>
              </FooterContext.Provider>
            </CartContext.Provider>
          </NotificationContext.Provider>
        </UserContext.Provider>
      </MenuContext.Provider>
    </CacheProvider>
  );
}
