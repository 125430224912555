import '@ui/yup/Yup';

import * as Yup from 'yup';

const RegisterSchema = Yup.object().shape({
  firstname: Yup.string()
    .required('Firstname is required'),
  lastname: Yup.string()
    .required('Lastname is required'),
  email: Yup.string()
    .email('Please enter a valid email address')
    .required('Email is required'),
  phone: Yup.string().phone().required('Please enter a phone number'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
});

export const initialValues: RegisterForm = {
  firstname: '',
  lastname: '',
  phone: '',
  email: '',
  password: '',
};

export default RegisterSchema;
