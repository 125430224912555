import makeStyles from '@ui/utils/makeStyles';

export default makeStyles()((theme) => ({
  footerWrapper: {
    width: '100vw',
    minHeight: 80,
    display: 'flex',
    alignItems: 'center',
    background: theme.palette.pink.main,
    zIndex: 10,
    padding: theme.spacing(10, 0, 10, 0),
    fontFamily: theme.gh_vars.circular,
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(20, 0, 10, 0),
    }
  },
  footerContent: {
    width: '100%',
    maxWidth: theme.gh_vars.maxWidth,
    margin: '0 auto',
    padding: theme.spacing(0, 3),
  },
  footerIcon: {
    height: 60,
    width: 'auto',
  },
  navigationList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none',
  },
  navigationPrimaryItem: {
    fontSize: 36,
    lineHeight: 1.2,
    marginTop: 18,
    marginBottom: 18,
    fontFamily: theme.gh_vars.circular,
    '&:first-of-type': {
      marginTop: 0,
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
    '& a': {
      color: theme.palette.common.black,
      textDecoration: 'none',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: 48,
    },
  },
  navigationSecondaryItem: {
    fontSize: 18,
    lineHeight: 1.2,
    marginTop: 38,
    marginBottom: 38,
    fontFamily: theme.gh_vars.circular,
    '&:first-of-type': {
      marginTop: 23,
    },
    '&:last-of-type': {
      marginBottom: 0,
    },
    '& a': {
      color: theme.palette.common.black,
      textDecoration: 'none',
    }
  },
  footerSocial: {
    margin: theme.spacing(2),
    '&:first-of-type': {
      marginLeft: 0,
    }
  },
  footerSocialIcon: {
    fill: theme.palette.common.black,
    height: 25,
    width: 'auto',
  },
  footerLegal: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(20),
    },
  },
  footerLegalListWrapper: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    }
  },
  footerLegalList: {
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
    }
  },
  footerLegalItem: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.up('md')]: {
      marginLeft: 20,
      marginRight: 20,
      marginTop: 0,
      marginBottom: 0,
    },
    '& a': {
      lineHeight: '28px',
      color: theme.palette.common.black,
    }
  }
}));
