import { functions } from '@app/firebase';
import CONFIG from '@config';
import Sentry from '@integrations/Sentry';
import checkRoles from '@ui/utils/checkRoles';
import userFullName from '@ui/utils/userFullName';
import { httpsCallable } from 'firebase/functions';
import { StreamChat } from 'stream-chat';


const client = StreamChat.getInstance(CONFIG.stream.api_key);
const getStreamUserToken = httpsCallable<void, {token: string}>(functions, 'http-getStreamUserToken');

export default async function connectNewStreamUser(user: guesthouse.User | Partial<guesthouse.User>) {
  let streamUser;

  try {
    const { data } = await getStreamUserToken();
    const { token } = data;
    
    streamUser = client.connectUser(
      {
        id: user.docID,
        name: userFullName(user),
        role: checkRoles(['admin'], user.roles) ? 'admin' : 'user',
        image: user?.photoURL,
      },
      token,
    );
  } catch (e) {
    Sentry.captureException(e);
  }
  return { streamUser };
}
