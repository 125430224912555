import Image, { ImageProps } from 'next/image';
import React from 'react';

type PrimaryImageProps = {
  item: Partial<guesthouse.Home | guesthouse.Product | guesthouse.Room>;
  alt?: string;
  size?: string;
  fallback?: string;
  className?: string;
  style?: React.CSSProperties;
  ImageProps?: Partial<ImageProps>;
}

const PrimaryImage : React.FC<PrimaryImageProps> = (props: PrimaryImageProps) => {
  const {
    item,
    alt,
    size,
    fallback,
    className,
    ImageProps,
  } = props;

  const primaryPhoto = item?.primaryPhoto;

  let photo, url;

  if (primaryPhoto) {
    photo = primaryPhoto;
    url = photo[`${size}_url`];
  } else if (fallback) {
    url = fallback;
  }

  return (
    <Image
      src={url || '/images/misc/product-default.png'}
      alt={alt}
      className={className}
      {...ImageProps}
      fill
      sizes="100vw"
      style={{
        objectFit: 'cover'
      }}
    />
  );
};

PrimaryImage.defaultProps = {
  size: 'medium',
  style: {},
  fallback: '/images/misc/product-default.png',
  alt: '',
  ImageProps: {},
};

export default React.memo(PrimaryImage);
