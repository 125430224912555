import useMediaQuery from '@mui/material/useMediaQuery';

import { useStyles } from '../utils/makeStyles';

export type UseBreakpointsValue = {
  xs: boolean;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
}

export default function useBreakpoints() : UseBreakpointsValue {
  const { theme } = useStyles();
  const xs = useMediaQuery(theme.breakpoints.up('xs'));
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));

  return {
    xs,
    sm,
    md,
    lg,
    xl
  };
}
