import theme from '@ui/theme';
import React from 'react';

export const defaultMenuContext: MenuContext = {
  open: false,
  backgroundColor: theme.palette.common.white,
  borderColor: theme.palette.common.white,
  showHeaderNavigation: true,
  showFooter: true,
  setContext: () => {}
};

export default React.createContext<MenuContext>(defaultMenuContext);
