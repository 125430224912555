
import useCart from '@hooks/useCart';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import formatCurrency from 'format-currency';
import round from 'lodash.round';
import React, { } from 'react';

const CartTotals: React.FC = () => {
  const {
    subtotal,
    shippingTotal,
    sale,
    total,
  } = useCart();

  return (
    <TableContainer>
      <Table aria-label="Checkout">
        <TableBody>

          <TableRow>
            <TableCell align="left">
              Subtotal
            </TableCell>

            <TableCell align="right">
              {formatCurrency(round(subtotal, 2), { format: '%s%v', symbol: '$' })}
            </TableCell>
          </TableRow>

          {
            sale > 0 && (
              <TableRow>
                <TableCell align="left">
                  Sale
                </TableCell>

                <TableCell align="right">
                  (
                  {formatCurrency(round(sale, 2), { format: '%s%v', symbol: '$' })}
                  )
                </TableCell>
              </TableRow>
            )
          }

          <TableRow>
            <TableCell align="left">
              Shipping
            </TableCell>

            <TableCell align="right">
              {formatCurrency(round(shippingTotal, 2), { format: '%s%v', symbol: '$' })}
            </TableCell>
          </TableRow>

          <TableRow>
            <TableCell
              align="left"
              style={{ border: 0 }}
            >
              <strong>
                Total
              </strong>
            </TableCell>

            <TableCell
              align="right"
              style={{ border: 0 }}
            >
              <Typography
                component="span"
                variant="h5Alt"
                display="block"
              >
                {formatCurrency(round(total, 2), { format: '%s%v', symbol: '$' })}
              </Typography>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CartTotals;
