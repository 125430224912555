import makeStyles from '@ui/utils/makeStyles';

type StyleProps = {
  open: boolean;
}

export default makeStyles<StyleProps>()((theme, { open }) => ({
  perspectiveWrapper : {
    perspective: '2000px',
    width: '100vw',
    top: 80,
    zIndex: 40,
    position: 'fixed',
    backgroundColor: 'inherit',
    visibility : open ? 'visible': 'hidden',
  },
  wrapper: {
    width: '100%',
    height: '100%',
    overflow: 'auto',
    transition: 'all .3s',
    color: theme.palette.common.black,
    transformOrigin: 'top',
    backgroundColor: 'inherit',
    transform: open ? 'rotateX(0deg)' : 'rotateX(-15deg)',
    opacity: open ? 1 : 0,
    transformStyle: 'preserve-3d',
    position: 'relative',
  },
  screen: {
    padding: theme.spacing(0, 3, 3, 3),
    position: 'absolute',
    top: 0,
    left: 0,
    minHeight: '100%',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
  },

}));
