import { functions } from '@app/firebase';
import EmailForm from '@forms/Email';
import LoginForm from '@forms/Login';
import RegisterForm from '@forms/Register';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import usePrevious from '@ui/hooks/usePrevious';
import { useStyles } from '@ui/utils/makeStyles';
import { validateEmailExists } from '@ui/utils/validators';
import { User as FirebaseUser } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import debounce from 'lodash/debounce';
import React, { useState } from 'react';

const sendLoginLink = httpsCallable(functions, 'http-sendLoginLink');
const sendResetPasswordLink = httpsCallable(functions, 'http-sendResetPasswordLink');

const validateEmailExistsDebounce = debounce(validateEmailExists, 500);

type ViewType = 'login' | 'register' | 'reset-password' | 'send-link' | 'check-email-link-sent' | 'check-email-reset-password';
interface LoginRegisterProps {
  registerButtonText?: string;
  loginButtonText?: string;
  defaultView?: ViewType;
  onLoginSuccess?: (user: FirebaseUser) => void;
  onLoginStart?: () => void;
  onLoginError?: (e?: Error) => void;
}

const LoginRegister: React.FC<LoginRegisterProps> = (props: LoginRegisterProps) => {
  const { onLoginSuccess, onLoginStart, onLoginError, defaultView } = props;
  const [view, setView] = useState(defaultView);
  const lastView = usePrevious(view);
  const { theme } = useStyles();

  if (view === 'check-email-link-sent') {
    return (
      <>
        <Box>
          <Typography
            display="block"
            component="div"
            variant="h4Alt"
            style={{ marginRight: theme.spacing(2), marginBottom: theme.spacing(1) }}
          >
            Please check your email
          </Typography>
          <Typography
            display="block"
          >
            We&apos;ve sent an email to your inbox that will allow you to login.
          </Typography>
        </Box>
      </>
    );
  }

  if (view === 'check-email-reset-password') {
    return (
      <>
        <Box>
          <Typography
            display="block"
            component="div"
            variant="h4Alt"
            style={{ marginRight: theme.spacing(2), marginBottom: theme.spacing(1) }}
          >
            Please check your email
          </Typography>
          <Typography
            display="block"
          >
            We&apos;ve sent an email to your inbox that will allow you to reset your password.
          </Typography>
        </Box>
      </>
    );
  }

  if (view === 'login') {
    return (
      <>
        <Box
          display="flex"
          alignItems="center"
        >
          <Typography
            component="span"
            variant="h6Alt"
            style={{ marginRight: theme.spacing(2) }}
          >
            Need an account?
          </Typography>
          <Typography
            variant="h6Alt"
            component="a"
            href="#"
            color="primary"
            data-test="login-register-signup"
            onClick={(e) => {
              e.preventDefault();
              setView('register');
            }}
          >
            Sign up now
          </Typography>
        </Box>
        <LoginForm
          submitText={props.loginButtonText}
          onLoginSuccess={onLoginSuccess}
          onLoginStart={onLoginStart}
          onLoginError={onLoginError}
        />
        <Typography
          align="center"
          component="a"
          display="block"
          style={{ marginTop: theme.spacing(2) }}
          href="#"
          color="black"
          fontFamily={theme.gh_vars.circular}
          fontSize={12}
          fontWeight={600}
          data-test="send-link"
          onClick={(e) => {
            e.preventDefault();
            setView('send-link');
          }}
        >
          Send me a link to login
        </Typography>

        <Typography
          align="center"
          component="a"
          display="block"
          style={{ marginTop: theme.spacing(2) }}
          href="#"
          color="black"
          fontFamily={theme.gh_vars.circular}
          fontSize={12}
          fontWeight={600}
          data-test="reset-password"
          onClick={(e) => {
            e.preventDefault();
            setView('reset-password');
          }}
        >
          Reset password
        </Typography>
      </>
    );
  }

  if (view === 'register') {
    return (
      <>
        <Box
          display="flex"
          alignItems="center"
        >
          <Typography
            component="span"
            variant="h6Alt"
            style={{ marginRight: theme.spacing(2) }}
          >
            Account Info
          </Typography>
          <Typography
            variant="h6Alt"
            component="a"
            href="#"
            color="primary"
            data-test="login-register-login"
            onClick={(e) => {
              e.preventDefault();
              setView('login');
            }}
          >
            Login
          </Typography>
        </Box>

        <RegisterForm
          submitText={props.registerButtonText}
          onLoginSuccess={onLoginSuccess}
          onLoginStart={onLoginStart}
          onLoginError={onLoginError}
        />
      </>
    );
  }

  if (view === 'reset-password') {
    return (
      <>
        <Box
          display="flex"
          alignItems="center"
        >
          <Typography
            component="span"
            variant="h6Alt"
            style={{ marginRight: theme.spacing(2) }}
          >
            Reset password
          </Typography>
        </Box>
        <EmailForm
          validate={validateEmailExistsDebounce}
          onSubmit={(email) => {
            return sendResetPasswordLink({
              email: email,
              next: window.location.href,
            })
              .then(() => setView('check-email-reset-password'));
          }}
        />
        <Typography
          align="center"
          component="a"
          display="block"
          style={{ marginTop: theme.spacing(2) }}
          href="#"
          color="black"
          fontFamily={theme.gh_vars.circular}
          fontSize={12}
          fontWeight={600}
          onClick={(e) => {
            e.preventDefault();
            setView(lastView);
          }}
        >
          Cancel
        </Typography>
      </>
    );
  }

  if (view === 'send-link') {
    return (
      <>
        <Box
          display="flex"
          alignItems="center"
        >
          <Typography
            component="span"
            variant="h6Alt"
            style={{ marginRight: theme.spacing(2) }}
          >
            Login with email
          </Typography>
        </Box>
        <EmailForm
          validate={validateEmailExistsDebounce}
          onSubmit={(email) => {
            window.localStorage.setItem('emailForSignIn', email);

            return sendLoginLink({
              email: email,
              next: window.location.href,
            })
              .then(() => setView('check-email-link-sent'));
          }}
        />
        <Typography
          align="center"
          component="a"
          display="block"
          style={{ marginTop: theme.spacing(2) }}
          href="#"
          color="black"
          fontFamily={theme.gh_vars.circular}
          fontSize={12}
          fontWeight={600}
          onClick={(e) => {
            e.preventDefault();
            setView(lastView);
          }}
        >
          Cancel
        </Typography>
      </>
    );
  }

  return null;
};

LoginRegister.defaultProps = {
  defaultView: 'login',
  loginButtonText: 'Sign in and continue',
  registerButtonText: 'Sign up and continue',
};

export default LoginRegister;
