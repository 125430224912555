import { database } from '@app/firebase';
import { doc as firestoreDoc,DocumentData, DocumentSnapshot, onSnapshot } from 'firebase/firestore';
import { useEffect, useState } from 'react';

interface UseDocumentReturn<T> {
  error: Error | boolean;
  loading: boolean;
  doc: DocumentSnapshot<T> | null
}

function useDocument<T = DocumentData>(path: string): UseDocumentReturn<T> {
  const [error, setError] = useState<Error | boolean>(false);
  const [loading, setLoading] = useState(true);
  const [doc, setDoc] = useState<DocumentSnapshot<T> | null>(null);

  useEffect(
    () => {
      const unsubscribe = onSnapshot(firestoreDoc(database, path), (doc : DocumentSnapshot<T>) => {
        setLoading(false);
        setDoc(doc as DocumentSnapshot<T>);
      },
      err => {
        setError(err); 
      }
      );

      return () => unsubscribe();
    }, [path]
  );
  
  return {
    error,
    loading,
    doc,
  };
}

export default useDocument;
